//@ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Link from "next/link";
import { bidModalHide } from "../../redux/modalSlice";
import { useForm } from "react-hook-form";
import { ThirdwebSDK, useSigner } from "@thirdweb-dev/react";
import { logger } from "../../utils/arbLogger";
import SpinningButton from "../arbipegs/button/SpinningTransactionButton";
import arbConvert from '../../utils/arbConvert';
import { useExchangeRate } from '../../hooks/useExchangeRate';
import ArbitrumSVG from "../../public/images/svg/ArbitrumLogoSVG";

const BidModal = () => {
  const { bidModal, bidItem } = useSelector((state) => state.modal);

  const dispatch = useDispatch();

  const [buttonLoading, setButtonLoading] = useState(false);
  const { register, handleSubmit } = useForm();
  const { data: arbRate } = useExchangeRate("ARB")
  const signer = useSigner();
  const { me: meCache } = useSelector((state) => state.cache);
  logger.debug("MECACHED", meCache)
  logger.debug("bidItem", bidItem)

  const makeBid = async (bidPrice) => {
    setButtonLoading(true);
    try {
      const sdk = ThirdwebSDK.fromSigner(signer, process.env.NEXT_PUBLIC_ACTIVE_CHAIN_ID, {
        clientId: process.env.NEXT_PUBLIC_THIRDWEB_API_KEY
      });
      const contractMarketplace = await sdk.getContract(process.env.NEXT_PUBLIC_MARKETPLACE_CONTRACT_ADDRESS);
      const tx = await contractMarketplace.englishAuctions.makeBid(
        bidItem.auctionId,
        bidPrice
      );
      const receipt = tx.receipt; // the transaction receipt
      const id = tx.id;
      logger.debug("ID", id);
      dispatch(bidModalHide());
      const res = await fetch("/api/marketplace/ping", {
        method: "POST",
        body: JSON.stringify({}),
      }).then((res) => res.json());
      logger.debug("Reindex Marketplace", res);
      setButtonLoading(false);
    } catch (e) {
      setButtonLoading(false);
      logger.info("Transaction didn't complete:", e);
    }
  };

  const onFormSubmitBid = (data) => {
    logger.debug("SUBMIT BID", data, bidItem.auctionId);
    makeBid(data.price);
  };

  const onBidSubmitErrors = (errors) => logger.error("ERRORS", errors);

  const modalContentRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        bidModal &&
        modalContentRef.current &&
        !modalContentRef.current.contains(event.target)
      ) {
        const targetClassList = event.target.classList;
        logger.debug("targetClassList", targetClassList);
        if (
          !targetClassList.contains("modal-header") &&
          !targetClassList.contains("modal-footer") &&
          !targetClassList.contains("not-outside")
        ) {
          dispatch(bidModalHide());
        }
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        dispatch(bidModalHide());
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [bidModal, dispatch]);

  return (
    <>
      {bidModal && (
        <div className="modal backdrop-blur-md show block">
          <div className="modal-dialog max-w-2xl min-w-[35%]">
            <form onSubmit={handleSubmit(onFormSubmitBid, onBidSubmitErrors)}>
              <div className="modal-content dark:bg-jacarta-900 bg-white border dark:border-jacarta-600 border-jacarta-100">
                <div className="modal-header">
                  <h5 className="modal-title" id="placeBidLabel">
                    Bid
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => dispatch(bidModalHide())}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                    </svg>
                  </button>
                </div>

                <div
                  className="modal-body p-6 dark:bg-jacarta-900 bg-white"
                  ref={modalContentRef}
                >
                  <div className="mb-2 flex items-center justify-between">
                    {/* Modal content */}
                  </div>
                  <Link
                  href="/collections"
                  className="text-accent text-xl font-medium"
                >
                  {bidItem.tokenTitle}
                </Link>

                  <div className="dark:border-jacarta-600 border-jacarta-100 relative flex items-center border-b py-4">
                    <figure className="mr-5 self-start">
                      <img
                        src={bidItem.tokenImage}
                        alt={bidItem.tokenTitle}
                        className="rounded-2lg w-[200px] h-[200px]"
                        loading="lazy"
                      />
                    </figure>

                    <div>{/* Additional content */}</div>
                  </div>

                  <div className="mb-2 flex items-center justify-between">
                    <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white">
                      Price
                    </span>
                  </div>

                  <div className="dark:border-jacarta-600 border-jacarta-100 relative mb-2 flex items-center overflow-hidden rounded-lg border">
                    <div className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 bg-light-base flex flex-1 items-center self-stretch border-r px-2">
                      <span>
                        <ArbitrumSVG />
                      </span>
                      <span className="font-display text-black dark:text-white text-sm ml-2">
                        ARB
                      </span>
                    </div>

                    <input
                      type="float"
                      className="focus:ring-accent h-12 w-full flex-[3] border-0 focus:ring-inse dark:text-white text-black dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 bg-light-base"
                      placeholder="Input price"
                      {...register("price", {
                        required: "Minimum price is " + bidItem.minBidPrice,
                        min: parseFloat(bidItem.minBidPrice),
                      })}
                      defaultValue={parseFloat(bidItem.minBidPrice)}
                    />
                  </div>
                  {meCache?.balance?.arb &&
                    <div className="text-right flex flex-col">
                      <span className="dark:text-jacarta-400 text-sm font-medium mt-5">
                        Balance: {meCache.balance.arb} ARB
                      </span>
                      <span className=" dark:text-jacarta-400 justify-end font-medium">
                        (${arbConvert(meCache.balance.arb, arbRate?.usd)})
                      </span>
                    </div>
                  }
                </div>

                <div className="modal-footer">
                  <div className="flex items-center justify-center space-x-4">
                    {!buttonLoading ? (
                      <button
                        className="bg-accent hover:bg-accent-dark hover:scale-105 rounded-xl py-3 px-8 text-center font-semibold text-white transition-all not-outside"
                      >
                        Bid
                      </button>
                    ) : (
                      <SpinningButton />
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default BidModal;
