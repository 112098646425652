import { useState, useEffect } from "react";
import { IArbCollectionMetadata } from "../type/arbipegs";
import { logger } from "../utils/arbLogger";
import { ThirdwebSDK } from "@thirdweb-dev/react";
import { trpc } from "../utils/trpc";
import { selectCollectionByAddress } from "../redux/cacheSlice"
import { useDispatch, useSelector } from "react-redux";
import { cacheCollection } from "../redux/cacheSlice";
import { getThidWebChain } from "../utils/alchemyWrapper";
import { erc721ABI } from 'wagmi'
import { addressZero } from "../consts/contracts";

export const useArbCollection = (collectionAddress: string) => {
    const dispatch = useDispatch();
    const [data, setData] = useState<IArbCollectionMetadata>();
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [fromApis, setFromApis] = useState<boolean>(false);
    const [fromRedis, setFromRedis] = useState<boolean>(false);
    const [fromRedux, setFromRedux] = useState<boolean>(false);

    //Fetch Redux Cache
    //@ts-ignore
    const cachedCollection = useSelector((state) => selectCollectionByAddress(state.cache, collectionAddress))

    logger.trace("==========TRACE HOOK==========")
    logger.trace("HOOK REDUX CACHED COLLECTION", cachedCollection);


    //Fetch Redis Cache
    const { data: cachedData, isLoading: cachedDataLoading, isFetched: cachedDataFetched } = trpc.cache.get.useQuery({ key: collectionAddress })
    logger.trace("HOOK REDIS CACHED DATA", cachedData, cachedDataLoading, cachedDataFetched);

    //TODO Not working if not on good chain
    //Fetch Thirdweb Contract Metadata
/*     const { contract: thirdwebContract } = useContract(collectionAddress);
    const { data: thirdwebConctractMetadata, isLoading: thirdwebLoading, isFetched: thirdwebFetched, isError: thirdwebError } = useContractMetadata(thirdwebContract);
    logger.trace("HOOK THIRDWEB COLLECTION METADATA INFO", thirdwebConctractMetadata, thirdwebLoading, thirdwebFetched, thirdwebError); */

    //TODO Not working if not on good chain
    //Fetch Thirdweb Rarity info
/*     const { data: thirdwebRoyalty, isLoading: thirdwebRoyaltyLoading, isFetched: thirdwebRoyaltyFetched, isError: thirdwebRoyaltyError } = useRoyaltySettings(thirdwebContract);
    logger.trace("HOOK THIRDWEB ROYALTY INFO", thirdwebRoyalty, thirdwebRoyaltyLoading, thirdwebRoyaltyFetched, thirdwebRoyaltyError); */

    //Fetch Sanity editorial Metadata
    const { data: sanityCollection, isLoading: sanityLoading, isFetched: sanityFetched } = trpc.collection.collection.useQuery({ address: collectionAddress });
    logger.trace("HOOK SANITY COLLECTION METADATA INFO", sanityCollection, sanityLoading, sanityFetched);

    //Fetch Alchemy Collection Metadata
    const { data: alchemyCollection, isLoading: alchemyLoading, isFetched: alchemyFetched } = trpc.collection.metadata.useQuery({ address: collectionAddress });
    logger.trace("HOOK ALCHEMY COLLECTION METADATA INFO", alchemyCollection, alchemyLoading, alchemyFetched);

    //Fetch total number of owners
    const { data: owners, isLoading: ownersLoading, isFetched: ownersFetched } = trpc.collection.owners.useQuery({ address: collectionAddress });
    logger.trace("HOOK WONERS INFO", owners, ownersLoading, ownersFetched);


    const mutation = trpc.cache.set.useMutation();


    //const nftRarity = trpc.nft.rarity.useQuery({ address: collectionAddress, tokenId: tokenId });


    useEffect(() => {
        logger.trace("TRACE IN EFFECT API TRIGGER", alchemyFetched, sanityFetched, loading, fromRedis)
        if (alchemyFetched && sanityFetched && ownersFetched) {
            const getName = () => {
                if (sanityCollection && sanityCollection.title && sanityCollection.title != "") return sanityCollection.title;
                if (alchemyCollection && alchemyCollection.name && alchemyCollection.name != "") return alchemyCollection.name;
                //if (thirdwebConctractMetadata && thirdwebConctractMetadata.name && thirdwebConctractMetadata.name != "") return thirdwebConctractMetadata.name;
                return collectionAddress;
            }

            const getSymbol = () => {
                if (alchemyCollection && alchemyCollection.symbol && alchemyCollection.symbol != "") return alchemyCollection.symbol;
                //if (thirdwebConctractMetadata && thirdwebConctractMetadata.symbol && thirdwebConctractMetadata.symbol != "") return thirdwebConctractMetadata.symbol;
                return "";
            }

            const getType = () => {
                if (alchemyCollection && alchemyCollection.tokenType) return alchemyCollection.tokenType;
                if (sanityCollection && sanityCollection.type && sanityCollection.type != "") return sanityCollection.type.toUpperCase();
                return "ERC721";
            }

            const getOwner = () => {
                if (sanityCollection && sanityCollection.ownerId != "") return sanityCollection.ownerId.toLowerCase();
                if (alchemyCollection && alchemyCollection.contractDeployer) return alchemyCollection.contractDeployer;
                return "";
            }

            const getTotalSupply = () => {
                if (alchemyCollection && alchemyCollection.totalSupply) return alchemyCollection.totalSupply;
                //@ts-ignore
                if (sanityCollection && sanityCollection.properties && sanityCollection.properties.numberOfItems) return sanityCollection.properties.numberOfItems;
                return "";
            }

            const arbCollection: IArbCollectionMetadata = {
                address: collectionAddress,
                name: getName(),
                symbol: getSymbol(),
                type: getType(),
                bannerUrl: sanityCollection?.bannerUrl ? sanityCollection.bannerUrl : "",
                avatarUrl: sanityCollection?.avatarUrl ? sanityCollection.avatarUrl : "",
                verified: sanityCollection?.verified ? sanityCollection.verified : false,
                description: sanityCollection?.description ? sanityCollection.description : "",
                amount: 0,
                links: sanityCollection?.links ? sanityCollection.links : [],
                properties: sanityCollection?.properties ? sanityCollection.properties : [],
                stats: sanityCollection?.stats ? sanityCollection.stats : [],
                royalty: 0,
                royaltyRecipient: addressZero,
                owner: getOwner(),
                totalSupply: getTotalSupply(),
                totalOwners: owners?.owners?.length?owners.owners.length:0,
                blockchain: "Arbitrum",
            }
            logger.trace("TRACE IN EFFECT API DO")
            setData(arbCollection);
            setLoading(false);
            setFromApis(true);
            //Refresh Redux Cache
            dispatch(cacheCollection(arbCollection));
            //Refresh Redis Cache
            mutation.mutate({ "key": collectionAddress, "value": JSON.stringify(arbCollection) });

        }

    }, [collectionAddress, alchemyFetched, sanityFetched, ownersFetched]);

    useEffect(() => {
        logger.trace("TRACE IN EFFECT REDIS TRIGGER", cachedDataFetched, loading)
        if (!fromRedux && !fromApis && cachedDataFetched && cachedData && cachedData.value) {
            logger.trace("TRACE IN EFFECT REDIS DO")
            setData(JSON.parse(cachedData.value));
            setFromRedis(true);
            logger.debug("HOOK REDIS CACHE HIT", JSON.parse(cachedData.value));
        }
    }, [cachedDataFetched, fromRedux, fromApis]);

    useEffect(() => {
        logger.trace("TRACE IN EFFECT REDUX TRIGGER", loading)
        if (loading && cachedCollection && cachedCollection.length == 1) {
            setData(cachedCollection[0]);
            setFromRedux(true);
            logger.trace("TRACE IN EFFECT REDUX DO")
        }
    }, [cachedCollection, loading]);

    useEffect(() => {
        const getTotalSupply = async (collectionAddress: string) => {
            try {
                const sdk = new ThirdwebSDK(getThidWebChain(collectionAddress), {
                    clientId: process.env.NEXT_PUBLIC_THIRDWEB_API_KEY
                  });
                const contract = await sdk.getContractFromAbi(collectionAddress, erc721ABI)
                const totalSupply = await contract.call("totalSupply");
                logger.trace("TRACE TOTAL SUPPLY", totalSupply);
            }
            catch (e) {
                logger.info("getTotalSupply Error", e)
            }
        }
        getTotalSupply(collectionAddress);
    }, [collectionAddress]);

    return { data: data, error: error, isLoading: loading, fromApis: fromApis, fromRedux: fromRedux, fromRedis: fromRedis };
};