import React, { FC, useState } from 'react';
import TimeSpanDropDown from '../../../components/dropdown/timeSpanDropDown';
import { useForm } from 'react-hook-form';
import { ThirdwebSDK, useSigner } from '@thirdweb-dev/react';
import { useDispatch, useSelector } from 'react-redux';
import { selModalHide } from '../../../redux/modalSlice';
import { logger } from '../../../utils/arbLogger';
import SpinningButton from '../../arbipegs/button/SpinningTransactionButton';
import arbConvert from '../../../utils/arbConvert';
import { useExchangeRate } from '../../../hooks/useExchangeRate';
import ArbitrumSVG from "../../../public/images/svg/ArbitrumLogoSVG";
import { useArbCollection } from '../../../hooks/useArbCollection';
import { useQuery } from '@tanstack/react-query';
import { marketplace_api_prefix } from '../../../consts/marketplace-indexer';
import { ethers } from 'ethers';

interface Props {
  tokenType?: string;
}

const defaultProps: Props = {
  tokenType: "ERC721"
}


const OfferTab: FC<Props> = ({ tokenType }) => {
  //@ts-ignore
  const { selItem } = useSelector((state) => state.modal);
  //@ts-ignore
  const { register, handleSubmit } = useForm();
  const { data: arbRate } = useExchangeRate("ARB")
  const { data: arbCollectionData, error: arbCollectionError, isLoading: arbCollectionLoading, fromApis: arbCollectionFromApis, fromRedux: arbCollectionFromRedux, fromRedis: arbCollectionFromRedis } = useArbCollection(selItem.contractAddress);
  logger.trace("ARB COLLECTION AUTCION DATA AGGREGATOR", arbCollectionData, arbCollectionLoading, arbCollectionFromApis, arbCollectionFromRedux, arbCollectionFromRedis)
  const { data: floor_price } = useQuery(['floor_price', selItem.contractAddress], async () => {
    const response = await fetch(process.env.NEXT_PUBLIC_INDEXER_URL + marketplace_api_prefix + process.env.NEXT_PUBLIC_INDEXER_MARKETPLACE_ID + "/collections/" + selItem.contractAddress + "/floor_price")
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    return response.json()
  })
  let formatter = Intl.NumberFormat('en', { notation: 'compact' });

  const signer = useSigner();
  const dispatch = useDispatch();
  const createEnglishAuction = async (auction: any) => {
    setButtonLoading(true);
    try {
      //@ts-ignore
      const sdk = ThirdwebSDK.fromSigner(signer, process.env.NEXT_PUBLIC_ACTIVE_CHAIN_ID, {
        clientId: process.env.NEXT_PUBLIC_THIRDWEB_API_KEY
      });
      //@ts-ignore
      const contractMarketplace = await sdk.getContract(process.env.NEXT_PUBLIC_MARKETPLACE_CONTRACT_ADDRESS);
      const tx = await contractMarketplace.englishAuctions.createAuction(auction);
      const receipt = tx.receipt; // the transaction receipt
      const id = tx.id;
      logger.debug("ID", id);
      dispatch(selModalHide());
      const res = await fetch("/api/marketplace/ping", {
        method: "POST",
        body: JSON.stringify({}),
      }).then((res) => res.json());
      logger.debug("Reindex Marketplace", res);
      setButtonLoading(false);
    }
    catch (e) {
      setButtonLoading(false);
      logger.info("Transaction didn't complete:", e);
    }
  }
  const [buttonLoading, setButtonLoading] = useState(false);
  //@ts-ignore
  const onFormSubmitEnglishAuction = data => {
    logger.debug("SUBMIT ENGLISH AUCTION", data)
    let duration = {};
    if (data.duration > 0) {
      duration = {
        startTimestamp: new Date(),
        endTimestamp: new Date(
          //new Date().getTime() + data.duration * 60 * 1000, // FOR TEST ONLY in minutes
          new Date().getTime() + data.duration * 24 * 60 * 60 * 1000, // IN DAYS
        ),
      }
    }
    createEnglishAuction({
      assetContractAddress: selItem.contractAddress,
      tokenId: selItem.tokenId,
      minimumBidAmount: data.price,
      buyoutBidAmount: 100000,
      currencyContractAddress: process.env.NEXT_PUBLIC_ARB_TOKEN_CONTRACT_ADDRESS,
      quantity: data?.amount ? data.amount : 1,
      timeBufferInSeconds: "900",
      bidBufferBps: "500",
      ...duration
    })
  };
  //@ts-ignore
  const onEnglishAuctionSubmitErrors = errors => console.error("ERRORS ENGLISH AUCTIONS", errors);

  return <>
    {/* <!-- Fixed Price --> */}
    <div>
      <div >
        <p className='mt-2 font-medium'>Bids below the start price won&apos;t be accepted</p>
      </div>
      <hr className='border-jacarta-100 dark:border-jacarta-600' />
      <div
        className="tab-pane fade show active mb-2"
        id="offers"
        role="tabpanel"
        aria-labelledby="offers-tab"
      >
        <form onSubmit={handleSubmit(onFormSubmitEnglishAuction, onEnglishAuctionSubmitErrors)}>

          <div className=" mt-10 mb-2 font-medium">
            <p>
              Starting Price
            </p>
          </div>
          <div className="dark:border-jacarta-600 border-jacarta-100 relative mb-2 flex items-center overflow-hidden rounded-lg border">
            <div className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 bg-light-base flex flex-1 items-center self-stretch border-r px-2">
              <span>

                <ArbitrumSVG />

              </span>
              <span className="font-display text-black dark:text-white text-sm ml-2">
                ARB
              </span>
            </div>

            <input
              type="float"
              className="focus:ring-accent h-12 w-full flex-[3] text-black dark:text-white border-0 dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 bg-light-base focus:ring-inse dark:text-jacarta-700"
              placeholder="Input price"
              {...register('price', { required: "Minimum price is 0", min: 0 })}
            //   value={ETHAmount}
            //   onChange={(e) => handleEThAmount(e)}
            />
          </div>
          <div className=" mb-10 text-accent font-medium">
            <p>Floor price {selItem.floorPrice ? selItem.floorPrice : floor_price?.data ? ethers.utils.formatUnits(floor_price?.data) : "N/A"} ARB (${arbConvert(selItem.floorPrice ? selItem.floorPrice : floor_price?.data ? ethers.utils.formatUnits(floor_price?.data) : "0", arbRate?.usd)})</p>
          </div>
          {(selItem.tokenTokenType == 'ERC1155') ? <>
            <div className='flex' >

              <p className='mb-2'>Amount:</p>  <p className=" ml-2 dark:text-jacarta-300 text-jacarta-400 " > X amount available </p>

            </div>

            <div className="dark:border-jacarta-600 border-jacarta-100 relative mb-2 flex items-center overflow-hidden rounded-lg border">
              <input
                type="number"
                min={1}
                max={selItem.tokenBalance}
                className="focus:ring-accent h-12 w-full flex-[3] border-0 focus:ring-inse dark:text-jacarta-700"
                placeholder="1"
                defaultValue={1}
                {...register('amount', { required: true, min: 1, max: selItem.tokenBalance })}
              //   value={ETHAmount}
              //   onChange={(e) => handleEThAmount(e)}
              />
            </div></>
            : <></>}
          <p className='mb-2 dark:text-white text-jacarta-900 font-medium'>Listing validity</p>
          <TimeSpanDropDown register={register} noZero={true} />


          <hr className='mb-3 mt-3 border-jacarta-100 dark:border-jacarta-600' />

          <p className="font-medium">Fees</p>
          <p className="font-medium">Creator Royalties (
            {//@ts-ignore
              selItem.creatorRoyalty ? selItem.creatorRoyalty : (arbCollectionData?.stats?.royalty || arbCollectionData?.stats?.royalty == 0) ? formatter.format(arbCollectionData?.stats?.royalty) : arbCollectionData?.properties?.Royalties}%)</p>
          <p className="font-medium">Platform Fees (3%)</p>
          <div className="">
            <div className="flex items-center justify-center space-x-4">
              {!buttonLoading ?
                <button
                  className="bg-accent hover:scale-105 hover:bg-accent-dark rounded-xl py-3 px-8 text-center font-semibold text-white transition-all"
                >
                  Start auction
                </button>
                :
                <SpinningButton />
              }
            </div>
          </div>
        </form>
      </div>
    </div>
  </>;
};

export default OfferTab;
