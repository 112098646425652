// @ts-nocheck
import Link from "next/link";
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { buyModalHide } from "../../redux/modalSlice";
import { ThirdwebSDK, useAddress, useSigner } from "@thirdweb-dev/react";
import { logger } from "../../utils/arbLogger";
import SpinningButton from "../arbipegs/button/SpinningTransactionButton";
import arbConvert from '../../utils/arbConvert';
import { useExchangeRate } from '../../hooks/useExchangeRate';
import ArbitrumSVG from "../../public/images/svg/ArbitrumLogoSVG";

const BuyModal = () => {
  const { buyModal, buyItem } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const { data: arbRate } = useExchangeRate("ARB")
  const signer = useSigner();
  const buyerAddress = useAddress();
  const { me: meCache } = useSelector((state) => state.cache);
  logger.debug("MECACHED", meCache)
  const buyNow = async (listingId, amount) => {
    setButtonLoading(true);
    try {
      const sdk = ThirdwebSDK.fromSigner(signer, process.env.NEXT_PUBLIC_ACTIVE_CHAIN_ID, {
        clientId: process.env.NEXT_PUBLIC_THIRDWEB_API_KEY
      });
      const contractMarketplace = await sdk.getContract(process.env.NEXT_PUBLIC_MARKETPLACE_CONTRACT_ADDRESS);
      const tx = await contractMarketplace.directListings.buyFromListing(
        listingId,
        amount,
        buyerAddress
      );
      const receipt = tx.receipt; // the transaction receipt
      logger.debug("TX", tx);
      dispatch(buyModalHide());
      const res = await fetch("/api/marketplace/ping", {
        method: "POST",
        body: JSON.stringify({}),
      }).then((res) => res.json());
      logger.debug("Reindex Marketplace", res);
      setButtonLoading(false);
    } catch (e) {
      setButtonLoading(false);
      logger.info("Transaction didn't complete:", e);
    }
  };

  const [buttonLoading, setButtonLoading] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        dispatch(buyModalHide());
      }
    };

    const handleEscKey = (e) => {
      if (e.key === "Escape") {
        dispatch(buyModalHide());
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [dispatch]);

  if (
    buyItem &&
    buyItem.contractAddress !== "0x0000000000000000000000000000000000000000"
  ) {
    return (
      <div>
        {/* <!-- Buy Now Modal --> */}
        <div
          className={
            buyModal ? "modal backdrop-blur-md show block" : "modal fade"
          }
        >
          <div className="modal-dialog max-w-2xl min-w-[35%]" ref={modalRef}>
            <div className="modal-content dark:bg-jacarta-900 border dark:border-jacarta-600 border-jacarta-100">
              <div className="modal-header">
                <h5 className="modal-title" id="buyNowModalLabel">
                  Checkout
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => dispatch(buyModalHide())}
                >
                  <svg
                    xmlns="/images"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="fill-jacarta-900 h-6 w-6 dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                  </svg>
                </button>
              </div>

              {/* <!-- Body --> */}
              <div className="modal-body p-6 dark:bg-jacarta-900 bg-white">
                <div className="mb-2 flex items-center justify-between"></div>
                <Link
                  href="/collections"
                  className="text-accent text-xl font-medium"
                >
                  {buyItem.tokenTitle}
                </Link>

                <div className="dark:border-jacarta-600 border-jacarta-100 relative flex items-center border-b py-4">
                  <figure className="mr-5 self-start">
                    <img
                      src={buyItem.tokenImage}
                      alt={buyItem.tokenTitle}
                      className="rounded-2lg w-[200px] h-[200px]"
                      loading="lazy"
                    />
                  </figure>
                </div>

                {/* <!-- Total --> */}
                <div className="dark:border-jacarta-600 border-jacarta-100 mb-2 flex items-center justify-between border-b py-2.5">
                  <span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                    You Pay
                  </span>
                  <div className="ml-auto">
                    <span className="flex items-center whitespace-nowrap">
                      <span data-tippy-content="ARB">
                        <ArbitrumSVG />
                      </span>
                      <span className="text-accent font-medium tracking-tight ml-2">
                        {buyItem.tokenPrice} ARB (${arbConvert(buyItem.tokenPrice, arbRate?.usd)})
                      </span>
                    </span>
                  </div>
                </div>

                {/* <!-- Terms --> */}
                {meCache?.balance?.arb &&
                  <div className="text-right flex flex-col">
                    <span className="dark:text-jacarta-400 text-sm font-medium">
                      Balance: {meCache.balance.arb} ARB
                    </span>
                    <span className=" dark:text-jacarta-400 justify-end font-medium">
                      (${arbConvert(meCache.balance.arb, arbRate?.usd)})
                    </span>
                  </div>
                }
                {/* <!-- Only display this when buying an erc1155 listing >1 --> */}
                {(buyItem?.balance != 1) ?
                  <div>
                    <div className="mb-2 mt-5 flex items-center justify-between">
                      <span className="font-display text-black dark:text-white text-sm font-semibold">
                        {buyItem?.balance} amount available:
                      </span>
                    </div>

                    <div className="dark:border-jacarta-600 border-jacarta-100 relative mb-2 flex items-center overflow-hidden rounded-lg border">
                      <input
                        type="number"
                        className="focus:ring-accent h-12 w-full flex-[3] border-0 focus:ring-inse dark:text-white text-black dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 bg-light-base"
                        placeholder="Quantity"
                      />
                    </div>
                  </div>
                  : <></>
                }
              </div>
              {/* <!-- end body --> */}

              <div className="modal-footer">
                <div className="flex items-center justify-center space-x-4">
                  {!buttonLoading ? (
                    <button
                      className="bg-accent hover:scale-105 hover:bg-accent-dark rounded-xl py-3 px-8 text-center font-semibold text-white transition-all"
                      onClick={() => buyNow(buyItem.listingId, 1)}
                    >
                      Buy Now
                    </button>
                  ) : (
                    <SpinningButton />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else return <></>;
};

export default BuyModal;
