import React from "react";

const ArbitrumSVG = () => (
    
    <svg id="c" data-name="Layer 3" xmlns="http://www.w3.org/2000/svg" height="30px" width="30px" viewBox="0 0 1500 1500">
      <path 
        fill="#213147" 
        d="m299.47,534.35v431.21c0,27.53,14.71,52.99,38.54,66.71l373.44,215.65c23.83,13.72,53.17,13.72,77,0l373.44-215.65c23.83-13.72,38.54-39.18,38.54-66.71v-431.21c0-27.53-14.71-52.99-38.54-66.71l-373.44-215.65c-23.83-13.72-53.17-13.72-77,0l-373.53,215.65c-23.83,13.72-38.45,39.18-38.45,66.71Z"/>
      <g>
        <path 
          fill="#12aaff" 
          d="m831.42,833.59l-53.26,146.05c-1.44,4.06-1.44,8.49,0,12.55l91.62,251.3,105.97-61.2-127.19-348.7c-2.89-8.03-14.26-8.03-17.15,0Z"/>
        <path 
          fill="#12aaff" 
          d="m938.21,587.97c-2.89-8.03-14.26-8.03-17.15,0l-53.26,146.05c-1.44,4.06-1.44,8.49,0,12.55l150.11,411.44,105.97-61.2-185.68-508.84Z"/>
      </g>
      <path 
        fill="#9dcced" 
        d="m749.91,268.15c2.62,0,5.24.72,7.58,1.99l404.13,233.34c4.69,2.71,7.58,7.76,7.58,13.09v466.59c0,5.42-2.89,10.38-7.58,13.09l-404.13,233.34c-2.26,1.35-4.96,1.99-7.58,1.99s-5.24-.72-7.58-1.99l-404.13-233.16c-4.69-2.71-7.58-7.76-7.58-13.09v-466.68c0-5.42,2.89-10.38,7.58-13.09l404.13-233.34c2.35-1.35,4.96-2.08,7.58-2.08m0-68.15c-14.35,0-28.8,3.7-41.7,11.19l-404.04,233.25c-25.82,14.89-41.7,42.43-41.7,72.21v466.59c0,29.79,15.89,57.32,41.7,72.21l404.13,233.34c12.91,7.4,27.26,11.19,41.7,11.19s28.8-3.7,41.7-11.19l404.13-233.34c25.82-14.89,41.7-42.43,41.7-72.21v-466.59c0-29.79-15.89-57.32-41.7-72.21l-404.22-233.25c-12.91-7.49-27.35-11.19-41.7-11.19h0Z"/>
      <polygon 
        fill="#213147" 
        points="482.63 1158.55 519.82 1056.73 594.65 1118.92 524.69 1182.83 482.63 1158.55"/>
      <g>
        <path 
          fill="#fff" 
          d="m715.88,483.26h-102.45c-7.67,0-14.53,4.78-17.15,12.01l-219.62,602.08,105.97,61.2,241.83-663.01c2.26-5.96-2.17-12.28-8.58-12.28Z"/>
        <path 
          fill="#fff" 
          d="m895.15,483.26h-102.45c-7.67,0-14.53,4.78-17.15,12.01l-250.76,687.48,105.97,61.2,272.97-748.41c2.17-5.96-2.26-12.28-8.58-12.28Z"/>
      </g>
    </svg>
  );

export default ArbitrumSVG;
