// @ts-nocheck
import "../styles/globals.css";
import "../styles/burger.css";
import type { AppProps } from "next/app";
import React, { useState, useEffect } from "react";
import { Analytics } from '@vercel/analytics/react';
import { ThemeProvider } from "next-themes";
import Layout from "../components/layout";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { useRouter } from "next/router";
import Meta from "../components/Meta";
import UserContext from "../components/UserContext";
import { useRef } from "react";

import { ThirdwebProvider,
  metamaskWallet,
  coinbaseWallet,
  walletConnect,
  magicLink,
  darkTheme,
  trustWallet,
  zerionWallet } from "@thirdweb-dev/react";

import { trpc } from '../utils/trpc';

import flagsmith from "flagsmith/isomorphic";
import { FlagsmithProvider } from 'flagsmith/react';

function MyApp({ Component, pageProps, flagsmithState  }: AppProps) {
  const router = useRouter();
  const pid = router.asPath;
  const scrollRef = useRef({
    scrollPos: 0,
  });

  // State to track the current theme
  const [currentTheme, setCurrentTheme] = useState("dark"); // Set an initial theme

  // Use useEffect to detect the theme and update currentTheme state
  useEffect(() => {
    // Use next-themes to get the current theme
    const systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light";

    setCurrentTheme(systemTheme);
  }, []);

  return (
    <>
      <Meta title="ArbiPegs | NFT marketplace on #Arbitrum" description="The newest NFT marketplace on #Arbitrum. Discover your favorite NFT collections and trade them using $ARB tokens!"  />
      <ThirdwebProvider activeChain={ process.env.NEXT_PUBLIC_ACTIVE_CHAIN_ID } clientId={ process.env.NEXT_PUBLIC_THIRDWEB_API_KEY }
        supportedWallets={[
          metamaskWallet({ recommended: true }),
          coinbaseWallet(),
          walletConnect(),
          trustWallet(),
          zerionWallet(),
          magicLink({
            apiKey: process.env.NEXT_PUBLIC_MAGIC_LINK_API_KEY,
            oauthOptions: {
              providers: [
                "google",
                "twitter",
              ],
            },
          }),
        ]}
        authConfig={{
          // Set this to your domain to prevent phishing attacks
          domain: "https://arbipegs.art",
          // The URL of your Auth API
          authUrl: "/api/auth",
          statement: "I agree to the terms of service",
          nonce: "1234",
          resources: ["https://arbipegs.art/tarms", "https://arbipegs.art/privacy-policy"],
        }}
      >
        <Provider store={store}>
          <FlagsmithProvider
            serverState={flagsmithState}
            options={{
              environmentID: process.env.NEXT_PUBLIC_FLAGSMITH_ENV_ID,
            }}
            flagsmith={flagsmith}>
            {/* Pass the current theme to the ThemeProvider */}
            <ThemeProvider enableSystem={true} attribute="class" defaultTheme="dark">
              <UserContext.Provider value={{ scrollRef: scrollRef }}>
                {pid === "/login" ? (
                  <>
                  <Component {...pageProps} />
                  <Analytics />
                  </>
                ) : (
                  <Layout>
                    <Component {...pageProps} />
                    <Analytics />
                  </Layout>
                )}
              </UserContext.Provider>
            </ThemeProvider>
          </FlagsmithProvider>
        </Provider>
      </ThirdwebProvider>
    </>
  );
}
//export default MyApp;

MyApp.getInitialProps = async () => {
  await flagsmith.init({ // fetches flags on the server and passes them to the App 
      environmentID: process.env.NEXT_PUBLIC_FLAGSMITH_ENV_ID,
  });
  return { flagsmithState: flagsmith.getState() }
}

export default trpc.withTRPC(MyApp);

