const SpinningTransactionButton = () => {
return (
    <button type="button" className="bg-indigo-400 w-[175px] h-[50px] bg-accent rounded-xl  h-max w-max rounded-lg text-white font-bold hover:bg-indigo-300 hover:cursor-not-allowed duration-[500ms,800ms]" disabled>
    <div className="flex items-center justify-center m-[10px]">
      <div className="h-5 w-5 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
      <p className="ml-2">Approving Transaction</p>
    </div>
  </button>
)
  }

  
  export default SpinningTransactionButton;