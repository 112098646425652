// @ts-nocheck
import Image from "next/legacy/image";
import Link from "next/link";
import DarkMode from "../mode/DarkMode";
import Logo from "./../../public/images/NEW-LOGO-ARBIPEGS-JULY-2023-LIGHT.png";
import WhiteLogo from "./../../public/images/NEW-LOGO-ARBIPEGS-JULY-2023-DARK.png";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "next/router";
import { useDispatch } from 'react-redux';
import {
  isChildrenPageActive,
  isParentPageActive,
} from "../../utils/daynamicNavigation";
import { useEffect, useState } from "react";
import WalletButton from "../wallet-btn/WalletButton";
import WalletButtonPhone from "../wallet-btn/WalletButtonPhone";
import { useAddress, useUser } from "@thirdweb-dev/react";
import { notificationModalShow } from "../../redux/modalSlice";
import { useArbUser } from "../../hooks/useArbUser";
import { logger } from "../../utils/arbLogger";
import Searchbar from "../search/Searchbar";
import SearchbarPhone from "../search/SearchbarPhone";
import phoneLogo from "./../../public/images/PHONE-NEW-LOGO-ARBIPEGS-JULY-2023-LIGHT.png"
import phoneWhiteLogo from "./../../public/images/PHONE-NEW-LOGO-ARBIPEGS-JULY-2023-DARK.png";


export default function Header01() {
  const walletAddress = useAddress()
  const [toggle, setToggle] = useState(false);
  const [isCollapse, setCollapse] = useState(null);
  const { user, isLoggedIn, isLoading } = useUser();
  const me = useArbUser(walletAddress, isLoggedIn)
  logger.debug("isLoggedIn", isLoggedIn, isLoading, user, walletAddress)

  logger.debug("WALLET", walletAddress)
  logger.debug("HEADER01_ME", me)
  // window resize
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 1024) {
        setToggle(false);
      }
    });
  });

  const route = useRouter();
  const dispatch = useDispatch();
  const router = useRouter();

  function goHome() {
    setToggle(false);
    router.push("/");
  }


  function goExplore() {
    setToggle(false);
    router.push("/explore");
  }

  function goCollection() {
    setToggle(false);
    router.push("/collections");
  }
  /* -------------------------------------------------------------------------- */
  /*                            daynamic navigations                            */
  /* -------------------------------------------------------------------------- */
  const home = {
    id: 1,
    name: "Home",
    pages: [
      {
        id: uuidv4(),
        name: "Home",
        path: "/",
      }
    ],
  };




  const explore = {
    id: 3,
    name: "Explore",
    pages: [
      {
        id: uuidv4(),
        name: "All NFTs",
        path: "/explore/all",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            height={24}
            className="h-4 w-4 fill-jacarta-700"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M22 12.999V20a1 1 0 0 1-1 1h-8v-8.001h9zm-11 0V21H3a1 1 0 0 1-1-1v-7.001h9zM11 3v7.999H2V4a1 1 0 0 1 1-1h8zm10 0a1 1 0 0 1 1 1v6.999h-9V3h8z" />
          </svg>
        ),
      },
      {
        id: uuidv4(),
        name: "Photography",
        path: "/explore/photography",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            height={24}
            className="h-4 w-4 fill-[#428AF8]"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M2 6c0-.552.455-1 .992-1h18.016c.548 0 .992.445.992 1v14c0 .552-.455 1-.992 1H2.992A.994.994 0 0 1 2 20V6zm2 1v12h16V7H4zm10 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0 2a5 5 0 1 1 0-10 5 5 0 0 1 0 10zM4 2h6v2H4V2z" />
          </svg>
        ),
      },
      {
        id: uuidv4(),
        name: "Art",
        path: "/explore/art",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            height={24}
            className="h-4 w-4 fill-[#10B981]"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M12 2c5.522 0 10 3.978 10 8.889a5.558 5.558 0 0 1-5.556 5.555h-1.966c-.922 0-1.667.745-1.667 1.667 0 .422.167.811.422 1.1.267.3.434.689.434 1.122C13.667 21.256 12.9 22 12 22 6.478 22 2 17.522 2 12S6.478 2 12 2zm-1.189 16.111a3.664 3.664 0 0 1 3.667-3.667h1.966A3.558 3.558 0 0 0 20 10.89C20 7.139 16.468 4 12 4a8 8 0 0 0-.676 15.972 3.648 3.648 0 0 1-.513-1.86zM7.5 12a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm9 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM12 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
          </svg>
        ),
      },
      {
        id: uuidv4(),
        name: "Sports",
        path: "/explore/sports",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            height={24}
            className="h-4 w-4 fill-[#737EF2]"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm.366 11.366l-3.469 6.01a8.053 8.053 0 0 0 4.459.51 9.937 9.937 0 0 1 .784-5.494l-1.774-1.026zm3.518 2.031a7.956 7.956 0 0 0-.587 3.894 8.022 8.022 0 0 0 3.077-2.456l-2.49-1.438zm-7.025-4.055a9.95 9.95 0 0 1-4.365 3.428 8.01 8.01 0 0 0 2.671 3.604l3.469-6.008-1.775-1.024zm11.103-.13l-.258.12a7.947 7.947 0 0 0-2.82 2.333l2.492 1.439a7.975 7.975 0 0 0 .586-3.893zM4 12c0 .266.013.53.038.789a7.95 7.95 0 0 0 3.078-2.454L4.624 8.897A7.975 7.975 0 0 0 4 12zm12.835-6.374l-3.469 6.008 1.775 1.025a9.95 9.95 0 0 1 4.366-3.43 8.015 8.015 0 0 0-2.419-3.402l-.253-.201zM12 4c-.463 0-.916.04-1.357.115a9.928 9.928 0 0 1-.784 5.494l1.775 1.025 3.469-6.01A7.975 7.975 0 0 0 12 4zm-3.297.71l-.191.088a8.033 8.033 0 0 0-2.886 2.367l2.49 1.438a7.956 7.956 0 0 0 .587-3.893z" />
          </svg>
        ),
      },
      {
        id: uuidv4(),
        name: "Collectibles",
        path: "/explore/collectibles",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            height={24}
            className="h-4 w-4 fill-[#FEB240]"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M17.5 2a4.5 4.5 0 0 1 2.951 7.897c.355.967.549 2.013.549 3.103A9 9 0 1 1 3.55 9.897a4.5 4.5 0 1 1 6.791-5.744 9.05 9.05 0 0 1 3.32 0A4.494 4.494 0 0 1 17.5 2zm0 2c-.823 0-1.575.4-2.038 1.052l-.095.144-.718 1.176-1.355-.253a7.05 7.05 0 0 0-2.267-.052l-.316.052-1.356.255-.72-1.176A2.5 2.5 0 1 0 4.73 8.265l.131.123 1.041.904-.475 1.295A7 7 0 1 0 19 13c0-.716-.107-1.416-.314-2.083l-.112-.33-.475-1.295 1.04-.904A2.5 2.5 0 0 0 17.5 4zM10 13a2 2 0 1 0 4 0h2a4 4 0 1 1-8 0h2z" />
          </svg>
        ),
      },
    ],
  };

  const collections = {
    id: 4,
    name: "Collections",
    pages: [
      {
        id: uuidv4(),
        name: "Collections",
        path: "/collections",
      },
    ],
  };

  const mobileCollapse = (id) => {
    if (isCollapse === id) {
      return setCollapse(null);
    }
    setCollapse(id);
  };

  return <>
    {/* main desktop menu sart*/}
    <header className="js-page-header fixed top-0 z-20 w-full bg-light-base dark:bg-jacarta-800 transition-colors">
    <div className="flex items-center pr-[1.6875rem] pl-[2.5rem] py-6 max-w-[1620px] mx-auto">
        <Link className="shrink-0 -ml-4 hover:scale-105 ease-in-out duration-300" href="/">

          <div className="hidden xml:dark:block">
            <Image
              src={WhiteLogo}
              height={41}
              width={190}
              alt="ArbiPegs | NFT Marketplace"
              className="max-h-7 h-auto "
            />
          </div>
          <div className="hidden xml:block dark:hidden">
            <Image
              src={Logo}
              height={41}
              width={190}
              alt="ArbiPegs | NFT Marketplace"
            />
          </div>
          <div className="hidden dark:block xml:hidden xml:dark:hidden">
            <Image
              src={phoneWhiteLogo}
              height={41}
              width={190}
              alt="ArbiPegs | NFT Marketplace"
              className="max-h-7 h-auto "
            />
          </div>
          <div className="xml:hidden dark:hidden">
            <Image
              src={phoneLogo}
              height={41}
              width={190}
              alt="ArbiPegs | NFT Marketplace"
              className="max-h-7 h-auto "
            />
          </div>

        </Link>
        {/* End  logo */}
        <div className="hidden lg:-ml-[50px] xl:-ml-[20px] xml:ml-[30px] lg:block ml-10">
          <Searchbar />
        </div> 
        {/* End Desktop search form */}

        <div className="js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent">
          <nav className="navbar w-full">
            <ul className="flex flex-col lg:flex-row">
              {/* home */}
              <li className="group">
                <Link href="/">

                  <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                    <span
                      className={
                        isParentPageActive(home.pages, route.asPath)
                          ? "text-accent dark:text-accent"
                          : ""
                      }
                    >
                      Home
                    </span>
                  </button>

                </Link>
              </li>

              {/* Collections */}
              <li className="group">
                <Link href="/collections">

                  <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                    <span
                      className={
                        isChildrenPageActive(collections.pages, route.asPath)
                          ? "text-accent dark:text-accent"
                          : ""
                      }
                    >
                      Collections
                    </span>
                  </button>

                </Link>
              </li>

              <li className="group">
                <Link href="/explore">

                  <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                    <span
                      className={
                        isChildrenPageActive(explore.pages, route.asPath)
                          ? "text-accent dark:text-accent"
                          : ""
                      }
                    >
                      Explore
                    </span>
                  </button>

                </Link>
              </li>



            </ul>
          </nav>
          {/* End menu for desktop */}

          <div className="ml-8 hidden items-center lg:flex xl:ml-12">

            {/* End metamask Wallet */}

            <div className="js-nav-dropdown group-dropdown relative">

              <div className="mr-6">
                <WalletButton className="dropdown-toggle" />
              </div>

            </div>

          </div>
          {isLoggedIn &&
            <>


              <div className="ml-10 lg:block">
                <Link
                  href="#" legacyBehavior>
                  <button
                    className="hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full dark:bg-jacarta-600 bg-jacarta-100 transition-colors hover:border-transparent focus:border-transparent dark:border-transparent"
                    onClick={() => dispatch(notificationModalShow())}
                  >
                    {!me?.allOfMe?.hasNotifications ?
                      <svg key="notificationWhiteDesktop" height="16" width="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="text-black dark:text-white"><g><path d="m21.379 16.913c-1.512-1.278-2.379-3.146-2.379-5.125v-2.788c0-3.519-2.614-6.432-6-6.92v-1.08c0-.553-.448-1-1-1s-1 .447-1 1v1.08c-3.387.488-6 3.401-6 6.92v2.788c0 1.979-.867 3.847-2.388 5.133-.389.333-.612.817-.612 1.329 0 .965.785 1.75 1.75 1.75h16.5c.965 0 1.75-.785 1.75-1.75 0-.512-.223-.996-.621-1.337z"/><path d="m12 24c1.811 0 3.326-1.291 3.674-3h-7.348c.348 1.709 1.863 3 3.674 3z"/></g></svg>
                      :
                      <svg key="notificationRedDesktop" height="16" width="16" fill="red" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><path d="m22 10.882c-.552 0-1-.448-1-1 0-2.805-1.092-5.441-3.075-7.425-.391-.391-.391-1.023 0-1.414s1.023-.391 1.414 0c2.361 2.361 3.661 5.5 3.661 8.839 0 .552-.448 1-1 1z"/></g><g><path d="m2 10.882c-.552 0-1-.448-1-1 0-3.339 1.3-6.478 3.661-8.839.391-.391 1.023-.391 1.414 0s.391 1.023 0 1.414c-1.983 1.983-3.075 4.62-3.075 7.425 0 .552-.448 1-1 1z"/></g><g><path d="m21.379 16.913c-1.512-1.278-2.379-3.146-2.379-5.125v-2.788c0-3.519-2.614-6.432-6-6.92v-1.08c0-.553-.448-1-1-1s-1 .447-1 1v1.08c-3.387.488-6 3.401-6 6.92v2.788c0 1.979-.867 3.847-2.388 5.133-.389.333-.612.817-.612 1.329 0 .965.785 1.75 1.75 1.75h16.5c.965 0 1.75-.785 1.75-1.75 0-.512-.223-.996-.621-1.337z"/><path d="m12 24c1.811 0 3.326-1.291 3.674-3h-7.348c.348 1.709 1.863 3 3.674 3z"/></g></svg>
                    }

                  </button></Link>
              </div>
              <div className="-mr-10">
                <Link
                  href={walletAddress ? "/user/" + walletAddress.toLowerCase() : "/"}
                  className="hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full dark:bg-jacarta-600 bg-jacarta-100 transition-colors hover:border-transparent focus:border-transparent dark:border-transparent"
                  aria-label="profile">

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                    className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                  </svg>

                </Link>
              </div>
            </>
          }
          <div className="ml-10">
            <DarkMode />
          </div>

          {/* End header right content (metamask and other) for desktop */}
        </div>
        {/* header menu conent end for desktop */}
        <div className="ml-auto flex lg:hidden">
          {isLoggedIn &&
            <>
              <div className=" lg:block">
                <Link
                  href="#" legacyBehavior>
                  <button
                    className="border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full dark:bg-jacarta-600 bg-jacarta-100 transition-colors hover:border-transparent focus:border-transparent dark:border-transparent"
                    aria-label="notifications"
                    onClick={() => dispatch(notificationModalShow())}
                  >

                    {!me?.allOfMe?.hasNotifications ?
                      <svg key="notificationWhitePhone" height="16" width="16" fill="currentcolor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><path d="m21.379 16.913c-1.512-1.278-2.379-3.146-2.379-5.125v-2.788c0-3.519-2.614-6.432-6-6.92v-1.08c0-.553-.448-1-1-1s-1 .447-1 1v1.08c-3.387.488-6 3.401-6 6.92v2.788c0 1.979-.867 3.847-2.388 5.133-.389.333-.612.817-.612 1.329 0 .965.785 1.75 1.75 1.75h16.5c.965 0 1.75-.785 1.75-1.75 0-.512-.223-.996-.621-1.337z"/><path d="m12 24c1.811 0 3.326-1.291 3.674-3h-7.348c.348 1.709 1.863 3 3.674 3z"/></g></svg>
                       :
                      <svg key="notificationRedPhone" height="16" width="16" fill="red" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><path d="m22 10.882c-.552 0-1-.448-1-1 0-2.805-1.092-5.441-3.075-7.425-.391-.391-.391-1.023 0-1.414s1.023-.391 1.414 0c2.361 2.361 3.661 5.5 3.661 8.839 0 .552-.448 1-1 1z"/></g><g><path d="m2 10.882c-.552 0-1-.448-1-1 0-3.339 1.3-6.478 3.661-8.839.391-.391 1.023-.391 1.414 0s.391 1.023 0 1.414c-1.983 1.983-3.075 4.62-3.075 7.425 0 .552-.448 1-1 1z"/></g><g><path d="m21.379 16.913c-1.512-1.278-2.379-3.146-2.379-5.125v-2.788c0-3.519-2.614-6.432-6-6.92v-1.08c0-.553-.448-1-1-1s-1 .447-1 1v1.08c-3.387.488-6 3.401-6 6.92v2.788c0 1.979-.867 3.847-2.388 5.133-.389.333-.612.817-.612 1.329 0 .965.785 1.75 1.75 1.75h16.5c.965 0 1.75-.785 1.75-1.75 0-.512-.223-.996-.621-1.337z"/><path d="m12 24c1.811 0 3.326-1.291 3.674-3h-7.348c.348 1.709 1.863 3 3.674 3z"/></g></svg>
                    }
                  </button></Link>
              </div>
              <div className="hidden lg:block">
                <Link
                  href={walletAddress ? "/profile/" + walletAddress.toLowerCase() : "/profile/user_avatar"}
                  className="hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full bg-jacarta-100 transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-jacarta-600"
                  aria-label="profile">

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                    className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                  </svg>

                </Link>
              </div>
            </>
          }
          <div className="lg:block hidden">
            <DarkMode />
          </div>
          <SearchbarPhone  />
          <button
            className="js-mobile-toggle hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full bg-jacarta-100 transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-jacarta-600"
            aria-label="open mobile menu"
            onClick={() => setToggle(true)}
          >
            <svg height="18" viewBox="0 -53 384 384" width="18" fill="currentcolor" xmlns="http://www.w3.org/2000/svg"><path d="m368 154.667969h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/><path d="m368 32h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/><path d="m368 277.332031h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/></svg>
          </button>
        </div>
        {/* End header right content  for mobile */}
      </div>
      {/* End flex item */}
    </header>
    {/* main desktop menu end */}

    {/* start mobile menu and it's other materials  */}
    <div
      className={`lg:hidden js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-20 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent ${toggle ? "nav-menu--is-open" : "hidden"
        }`}
    >
      <div className="t-0 dark:bg-jacarta-800 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden">
      <Link className="shrink-0 -ml-4" href="/" onClick={() => setToggle(false)}>
        <div className="dark:hidden">
          <Image
            src={phoneLogo}
            height={41}
            width={190}
            alt="ArbiPegs | NFT Marketplace"
            className="max-h-7 h-auto "
          />
        </div>

        <div className="hidden dark:block">
          <Image
            src={phoneWhiteLogo}
            height={41}
            width={190}
            alt="ArbiPegs | NFT Marketplace"
          />
        </div>
        </Link>
        <div className="flex">
          <div className="">
            <Link
              href={walletAddress ? "/user/" + walletAddress.toLowerCase() : "/"}
              className="hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full dark:bg-jacarta-600 bg-jacarta-100 transition-colors hover:border-transparent focus:border-transparent dark:border-transparent"
              aria-label="profile"
              onClick={() => setToggle(false)}
              >

              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
              </svg>

            </Link>
          </div>

          <div className="">
            <DarkMode />
          </div>

          <button
            className="js-mobile-close hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full bg-jacarta-100 transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-jacarta-600"
            onClick={() => setToggle(false)}
          >
            <svg id="Capa_1" enable-background="new 0 0 320.591 320.591" height="12" viewBox="0 0 320.591 320.591" width="12" fill="currentcolor" xmlns="http://www.w3.org/2000/svg"><g><g id="close_1_"><path d="m30.391 318.583c-7.86.457-15.59-2.156-21.56-7.288-11.774-11.844-11.774-30.973 0-42.817l257.812-257.813c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875l-259.331 259.331c-5.893 5.058-13.499 7.666-21.256 7.288z"/><path d="m287.9 318.583c-7.966-.034-15.601-3.196-21.257-8.806l-257.813-257.814c-10.908-12.738-9.425-31.908 3.313-42.817 11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414-6.35 5.522-14.707 8.161-23.078 7.288z"/></g></g></svg>
          </button>
        </div>
      </div>


      {/* mobile menu top header content */}



      <nav className="navbar w-full">
        <ul className="flex flex-col lg:flex-row mt-[100px]">
          <li>
            <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
              <Link href="/">
                <span className={
                  isParentPageActive(home.pages, route.asPath)
                    ? "text-accent dark:text-accent"
                    : ""
                } onClick={goHome}
                > Home
                </span>
              </Link>
            </button>
          </li>

          <li>
            <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
              <Link href="/collections">
                <span className={
                  isChildrenPageActive(collections.pages, route.asPath)
                    ? "text-accent dark:text-accent"
                    : ""
                } onClick={goCollection}
                > Collections
                </span>
              </Link>
            </button>
          </li>

          <li>
            <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
              <Link href="/explore">
                <span className={
                  isChildrenPageActive(explore.pages, route.asPath)
                    ? "text-accent dark:text-accent"
                    : ""
                } onClick={goExplore}
                > Explore
                </span>
              </Link>
            </button>
          </li>





        </ul>
      </nav>
      {/* End navbar mobile menu  */}

      <div className="mx-auto mt-[100px]" >
        <WalletButtonPhone
        />
      </div>



      <div className="mt-10 w-full lg:hidden">

        <hr className="dark:bg-jacarta-600 bg-jacarta-100 my-5 h-px border-0" />
        <div className="flex items-center justify-center space-x-5">
          <a className="group" href="https://twitter.com/ArbiPegs" >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="twitter"
              className="group-hover:fill-accent fill-jacarta-400 dark:fill-jacarta-300 h-5 w-5 dark:group-hover:fill-accent"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
            </svg>
          </a>


          <a className="group" href="https://arbipegs.medium.com/" >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="medium"
              className="group-hover:fill-accent fill-jacarta-400 dark:fill-jacarta-300 h-5 w-5 dark:group-hover:fill-accent"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="m24 24h-24v-24h24zm-8.986-15.006v7.326c0 .198 0 .234-.127.362l-1.302 1.264v.27h6.32v-.27l-1.257-1.234c-.091-.07-.148-.178-.148-.3 0-.022.002-.043.005-.064v.002-9.07c-.003-.019-.005-.04-.005-.062 0-.121.058-.229.148-.298l.001-.001 1.286-1.234v-.27h-4.456l-3.176 7.924-3.609-7.924h-4.675v.271l1.502 1.813c.127.115.207.281.207.466 0 .022-.001.043-.003.064v-.003 7.126c.007.041.011.088.011.136 0 .222-.088.423-.231.571l-1.69 2.054v.27h4.8v-.27l-1.691-2.054c-.149-.154-.241-.363-.241-.595 0-.04.003-.079.008-.117v.004-6.16l4.215 9.195h.49z"></path>
            </svg>
          </a>


          <a className="group" href="https://discord.gg/TMyfRw5S3Q">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="discord"
              className="group-hover:fill-accent fill-jacarta-400 dark:fill-jacarta-300 h-5 w-5 dark:group-hover:fill-accent"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z" />
            </svg>
          </a>

        </div>





      </div>

      {/* mt-10 w-full lg:hidden */}
    </div>
    {/* End mobile menu and it's other materials */}
  </>;
}
