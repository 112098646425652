import Head from "next/head";

const Meta = ({ title, keyword, desc, image }) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
        
        {/* Open Graph (OG) meta tags */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:image" content={image} /> {/* Set the image URL here */}
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "ArbiPegs | NFT marketplace on #Arbitrum",
  keyword:
    "bitcoin, blockchain, Ethereum, crypto, Arbitrum, Arbitrum One, ArbiPegs, Arbi, Pegs, Arbinaut, Astronaut, crypto collectibles, crypto makretplace, cryptocurrency, digital items, market, nft, nft marketplace, nfts, NFT react, non-fungible tokens, virtual asset, wallet, Arbitrum One, Arbitrum Rollups, Layer 2 scaling solution, Ethereum Layer 2, Arbitrum mainnet, Arbitrum DeFi, DeFi",
  desc: "The newest NFT marketplace on #Arbitrum. Discover your favorite NFT collections and trade them using $ARB tokens!",
  image: "https://arbipegs.art/images/Dark-ArbiPegs-Home.png", // Set your default image URL here
};

export default Meta;
