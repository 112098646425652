// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeOfferModalHide } from "../../redux/modalSlice";
import { useForm } from "react-hook-form";
import { ThirdwebSDK, useSigner } from "@thirdweb-dev/react";
import { logger } from "../../utils/arbLogger";
import SpinningButton from "../arbipegs/button/SpinningTransactionButton";
import TimeSpanDropDown from "../dropdown/timeSpanDropDown";
import ArbitrumSVG from "../../public/images/svg/ArbitrumLogoSVG";

const MakeOfferModal = () => {
  const { makeOfferModal, makeOffer } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const signer = useSigner();
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    const handleCloseModal = (event) => {
      if (event.key === "Escape") {
        dispatch(makeOfferModalHide());
      }
    };

    document.addEventListener("keydown", handleCloseModal);

    return () => {
      document.removeEventListener("keydown", handleCloseModal);
    };
  }, [dispatch]);

  const makeMarketPlaceOffer = async (offer) => {
    setButtonLoading(true);
    try {
      const sdk = ThirdwebSDK.fromSigner(signer, process.env.NEXT_PUBLIC_ACTIVE_CHAIN_ID, {
        clientId: process.env.NEXT_PUBLIC_THIRDWEB_API_KEY
      });
      const contractMarketplace = await sdk.getContract(process.env.NEXT_PUBLIC_MARKETPLACE_CONTRACT_ADDRESS);

      const tx = await contractMarketplace.offers.makeOffer(offer);
      const receipt = tx.receipt; // the transaction receipt
      const id = tx.id;
      logger.debug("ID", id);
      dispatch(makeOfferModalHide());
      const res = await fetch("/api/marketplace/ping", {
        method: "POST",
        body: JSON.stringify({}),
      }).then((res) => res.json());
      logger.debug("Reindex Marketplace", res);
      setButtonLoading(false);
    } catch (e) {
      setButtonLoading(false);
      logger.info("Transaction didn't complete:", e);
    }
  };

  const onFormSubmitOffer = (data) => {
    let duration = {};
    if (data.duration != 0) {
      duration = {
        endTimestamp: new Date(
          new Date().getTime() + data.duration * 24 * 60 * 60 * 1000
        ),
      };
    }
    makeMarketPlaceOffer({
      assetContractAddress: makeOffer.contractAddress,
      tokenId: makeOffer.tokenId,
      totalPrice: data.amount,
      currencyContractAddress: process.env.NEXT_PUBLIC_ARB_TOKEN_CONTRACT_ADDRESS,
      isReservedListing: false,
      quantity: data?.quantity ? data.quantity : 1,
      ...duration,
    });
  };

  const onOfferSubmitErrors = (errors) => console.error("ERRORS", errors);

  return (
    <div>
      <div
        className={
          makeOfferModal ? "modal backdrop-blur-md show block" : "modal fade"
        }
        onClick={() => dispatch(makeOfferModalHide())}
      >
        <div
          className="modal-dialog max-w-2xl min-w-[35%]"
          onClick={(e) => e.stopPropagation()}
        >
          <form
            onSubmit={handleSubmit(onFormSubmitOffer, onOfferSubmitErrors)}
          >
            <div className="modal-content dark:bg-jacarta-900 border dark:border-jacarta-600 border-jacarta-100 ">
              <div className="modal-header">
                <h5 className="modal-title" id="placeBidLabel">
                  Make an Offer
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => dispatch(makeOfferModalHide())}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                  </svg>
                </button>
              </div>

              {/* Body */}
              <div className="modal-body p-6 dark:bg-jacarta-900 rounded-b-xl">
                <div className="mb-2 flex items-center justify-between"></div>

                <a className="text-accent text-xl font-medium">
                  {makeOffer.tokenTitle}
                </a>

                <div className="dark:border-jacarta-600 border-jacarta-100 relative flex items-center border-b py-4">
                <figure className="mr-5 self-start">
                  {makeOffer?.tokenImage ? (
                    <img
                      src={makeOffer.tokenImage}
                      alt={makeOffer.tokenTitle}
                      className="rounded-xl w-[200px] h-[200px]"
                      loading="lazy"
                    />
                  ) : makeOffer?.animationUri ? (
                    <iframe
                      className="rounded-2lg w-[200px] h-[200px]"
                      src={makeOffer.animationUri}
                    ></iframe>
                  ) : (
                    <div className="rounded-2lg w-[200px] h-[200px] bg-gray-200"></div>
                  )}
                </figure>

                  <div></div>
                </div>
                {makeOffer.maxQty > 1 && (
                  <>
                    <div className="mb-2 flex items-center justify-between">
                      <span className="font-display text-black text-sm font-semibold dark:text-white">
                        Quantity
                      </span>
                    </div>


                  </>
                )}
                <div className="mb-2 flex items-center justify-between">
                  <span className="font-display text-black text-sm font-semibold dark:text-white">
                    Price
                  </span>
                </div>

                <div className="dark:border-jacarta-600 border-jacarta-100 relative mb-2 flex items-center overflow-hidden rounded-lg border">
                  <div className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 bg-light-base flex flex-1 items-center self-stretch border-r px-2">
                    <span>
                      <ArbitrumSVG />
                    </span>
                    <span className="font-display text-black dark:text-white text-sm ml-2">
                      ARB
                    </span>
                  </div>
                  <input
                    type="number"
                    step="0.01"
                    className="focus:ring-accent h-12 w-full flex-[3] border-0 focus:ring-inse dark:text-white text-black dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 bg-light-base"
                    placeholder="Price"
                    {...register("amount", {
                      required: "Price is required",
                      min: 0.01,
                    })}
                    defaultValue={makeOffer.price}
                    min={0.01}
                  />
                </div>

                <div className="mb-2 flex items-center justify-between">
                  <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white">
                    Duration
                  </span>
                </div>

                <TimeSpanDropDown register={register} />

                <div className="modal-footer">
                  <div className="flex items-center justify-center space-x-4">
                    {!buttonLoading ?
                      <button
                        className="bg-accent hover:bg-accent-dark hover:scale-105 rounded-xl py-3 px-8 text-center font-semibold text-white transition-all"
                      >
                        Make Offer
                      </button>
                      :
                      <SpinningButton />
                    }
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MakeOfferModal;
