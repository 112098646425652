import React from "react";
import {  useTheme } from "next-themes";


import {
  ThirdwebProvider,
  ConnectWallet,
  metamaskWallet,
  coinbaseWallet,
  walletConnect,
  trustWallet,
  zerionWallet,
  useAddress,
  useUser,
  magicLink,
  darkTheme,
  lightTheme,
} from "@thirdweb-dev/react";
import { useArbUser } from "../../hooks/useArbUser";
import styles from "styles/NftGallery.module.css";
import ArbitrumSVG from "../../public/images/svg/ArbitrumLogoSVG";

export default function WalletButton() {
  const walletAddress = useAddress();
  const { user, isLoggedIn, isLoading } = useUser();
  // @ts-ignore
  const me = useArbUser(walletAddress, isLoggedIn);

  // Function to format the balance
  const formatBalance = (balance: string | number) => {
    let formattedBalance = parseFloat(balance.toString());

    if (formattedBalance < 1000) {
      return formattedBalance.toFixed(0);
    } else if (formattedBalance >= 1000 && formattedBalance < 10000) {
      return (formattedBalance / 1000).toFixed(1) + "k";
    } else if (formattedBalance >= 10000 && formattedBalance < 1000000) {
      return (formattedBalance / 1000).toFixed(0) + "k";
    } else {
      return (formattedBalance / 1000000).toFixed(1) + "m";
    }
  };

  const { theme } = useTheme();

  const darkThemeConfig=darkTheme({
    fontFamily: "Rubik, sans-serif",
    colors: {
      modalBg: "#181923",
      accentText: "#448de7",
      danger: "Hi",
      borderColor: "#363A5D",
      accentButtonText: "#ffffff",
      dropdownBg: "#181923",
      primaryText: "#ffffff",
      secondaryText: "#ffffff",
      skeletonBg: "#ffffff",
      walletSelectorButtonHoverBg: "#448de7",
      primaryButtonBg: "#448de7",
    },
  })

  const lightThemeConfig=lightTheme({
    fontFamily: "Rubik, sans-serif",
    colors: {
      modalBg: "#ffffff",
      accentText: "#448de7",
      danger: "Hi",
      borderColor: "#E7E8EC",
      accentButtonText: "#000000",
      dropdownBg: "#ffffff",
      primaryText: "#000000",
      secondaryText: "#000000",
      skeletonBg: "#000000",
      walletSelectorButtonHoverBg: "#448de7",
      primaryButtonBg: "#448de7",
    },
  })

  // Determine the custom theme based on the current theme
  const customTheme = theme === "dark" ? darkThemeConfig : lightThemeConfig;

  return (
    <div className="max-w-[300px]">
      <ConnectWallet
      theme={customTheme}
        btnTitle="Connect wallet"
        modalTitle="Connect Your Wallet"
        
        dropdownPosition={{
          side: "bottom", // "top" | "bottom" | "left" | "right";
          align: "center", // "start" | "center" | "end";
        }}
        className={styles.buttonhomepagePhone}
        auth={{
          loginOptional: false,
        }}
        switchToActiveChain={false}
        welcomeScreen={{
          title: "ArbiPegs | NFT marketplace on #Arbitrum",
          subtitle: "Revolutionizing NFT trading with $ARB tokens on the Arbitrum blockchain.",
          img: {
              src: "/images/welcomewallet-accent.gif",
              width: 300,
              height: 300,
          },
          
      }}
      modalTitleIconUrl={
          "https://arbipegs.art/images/Logo-Only.png"
        }
        detailsBtn={() => {
            return (
                <button className={styles.buttonhomepageConnected}> 
                  <div className="mt-[5px] ml-[11px]">
                    <ArbitrumSVG />
                  </div> 
                  <div className="w-[80px] flex justify-between -ml-[5px]">
                    <p className=" ml-auto mt-2 my-auto flex  items-center"> 
                      {formatBalance(me?.allOfMe?.balance?.arb ?? 0)} 
                    </p>
                    <p className="mt-2 ml-[5px]">
                    ARB
                    </p>
                  </div>
                  {user && <p className=" ml-4 my-auto truncate w-[105px]"> {user.address} </p>}
                </button>
              );
        }}
      />
    </div>
  );
}
