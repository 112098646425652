import { createSlice } from "@reduxjs/toolkit";
import { addressZero } from "../consts/contracts";

const initialState = {
    bidModal: false,
    bidItem: { contractAddress: addressZero, tokenId: "0" },

    selModal: false,
    selItem: { contractAddress: addressZero, tokenId: "0" },

    cancelModal: false,
    cancelItem: { contractAddress: addressZero, tokenId: "0" },

    buyModal: false,
    buyItem: { contractAddress: addressZero, tokenId: "0" },

    makeOfferModal: false,
    makeOffer: { contractAddress: addressZero, tokenId: "0" },

    notificationModal: false,
    notificationItems: [],

    transferModal: false,
    transferItem: {},
};

export const modalSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        bidModalShow: (state) => {
            state.bidModal = true;
        },
        bidModalHide: (state) => {
            state.bidModal = false;
        },
        notificationModalShow: (state) => {
            state.notificationModal = true;
        },
        notificationModalHide: (state) => {
            state.notificationModal = false;
        },
        transferModalShow: (state) => {
            state.transferModal = true;
        },
        transferModalHide: (state) => {
            state.transferModal = false;
        },
        buyModalShow: (state) => {
            state.buyModal = true;
        },
        buyModalHide: (state) => {
            state.buyModal = false;
        },
        selModalShow: (state) => {
            state.selModal = true;
        },
        selModalHide: (state) => {
            state.selModal = false;
        },
        cancelModalShow: (state) => {
            state.cancelModal = true;
        },
        cancelModalHide: (state) => {
            state.cancelModal = false;
        },
        makeOfferModalShow: (state) => {
            state.makeOfferModal = true;
        },
        makeOfferModalHide: (state) => {
            state.makeOfferModal = false;
        },
        updateBidItem: (state, action) => {
            state.bidItem = action.payload;
        },
        updateBuyItem: (state, action) => {
            state.buyItem = action.payload;
        },
        updateSelItem: (state, action) => {
            state.selItem = action.payload;
        },
        updateCancelItem: (state, action) => {
            state.cancelItem = action.payload;
        },
        updateMakeOffer: (state, action) => {
            state.makeOffer = action.payload;
        },
        updateTransferItem: (state, action) => {
            state.transferItem = action.payload;
        },
    },
});

export const {
    bidModalShow,
    bidModalHide,
    buyModalShow,
    buyModalHide,
    transferModalShow,
    transferModalHide,
    notificationModalHide,
    notificationModalShow,
    selModalShow,
    selModalHide,
    cancelModalShow,
    cancelModalHide,
    makeOfferModalShow,
    makeOfferModalHide,
    updateBidItem,
    updateBuyItem,
    updateSelItem,
    updateCancelItem,
    updateMakeOffer,
    updateTransferItem,
} = modalSlice.actions;

export default modalSlice.reducer;
