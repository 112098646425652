import Footer from "./footer";
import Wallet_modal from "./modal/wallet_modal";
import BidModal from "./modal/bidModal";
import BuyModal from "./modal/buyModal";
import SelModal from "./modal/selModal";
import CancelModal from "./modal/cancelModal";
import MakeOfferModal from "./modal/makeOfferModal";
import NotificationModal from "./modal/NotificationModal";
import TransferModal from "./modal/transferModal";
import { useRouter } from "next/router";
import Header01 from "./header/Header01";
import Header02 from "./header/Header02";
import Header03 from "./header/Header03";

// @ts-ignore
export default function Layout({ children }) {
  const route = useRouter();
  // header start
  let header;
  if (
    route.asPath === "/maintenance"
  ) {
    header = <Header02 />;
  } else if (route.asPath === "/platform_status") {
    header = <Header03 />;
  } else {
    header = <Header01 />;
  }
  // header end

  return (
    <>
      {header}
      <Wallet_modal />
      <BidModal />
      <BuyModal />
      <SelModal />
      <CancelModal />
      <MakeOfferModal />
      <NotificationModal />
      <TransferModal />
      <main>{children}</main>
      <Footer />
    </>
  );
}
