import Link from "next/link";
import { socialIcons } from "../data/footer_data";
import PhoneLogoDark from "../public/images/PHONE-NEW-LOGO-ARBIPEGS-JULY-2023-DARK.png";
import phoneLogoLight from "../public/images/PHONE-NEW-LOGO-ARBIPEGS-JULY-2023-LIGHT.png";
import WhiteLogo from "../public/images/NEW-LOGO-ARBIPEGS-JULY-2023-DARK.png";
import Logo from "../public/images/NEW-LOGO-ARBIPEGS-JULY-2023-LIGHT.png";
import Image from "next/image";

const footer = () => {
  return <>
    {/* <!-- Footer --> */}
    <footer className="dark:bg-jacarta-900 page-footer bg-white">
      <div className="container max-w-[1620px] px-[1.6875rem] my-10">
        <div className="">
          <div className="">
            {/* <!-- Logo --> */}
            <div className="flex flex-col sm:flex-row justify-between">
              <div className="flex items-center">
              <div className="flex items-center">
                <Link className="cursor-pointer" href="/">
                  <div className="hidden md:dark:block hover:scale-105 ease-in-out duration-300">
                    <Image
                      src={WhiteLogo}
                      height={41}
                      width={190}
                      alt="ArbiPegs | NFT Marketplace"
                    />
                  </div>
                  <div className="hidden md:block dark:hidden hover:scale-105 ease-in-out duration-300">
                    <Image
                      src={Logo}
                      height={41}
                      width={190}
                      alt="ArbiPegs | NFT Marketplace"
                    />
                  </div>
                  <div className="hidden dark:block md:hidden md:dark:hidden hover:scale-105 ease-in-out duration-300">
                    <Image
                      src={PhoneLogoDark}
                      height={41}
                      width={190}
                      alt="ArbiPegs | NFT Marketplace"
                    />
                  </div>
                  <div className="md:hidden dark:hidden hover:scale-105 ease-in-out duration-300">
                    <Image
                      src={phoneLogoLight}
                      height={41}
                      width={190}
                      alt="ArbiPegs | NFT Marketplace"
                    />
                  </div>
                </Link>
              </div>

                <div className="dark:text-jacarta-300 mr-auto text-jacarta-400 sm:ml-4 font-medium text-sm ">
                  <span className="flex-row flex">© {new Date().getFullYear()} &nbsp; ArbiPegs</span>
                </div>
              </div>
              {/* <!-- Socials --> */}
              <div className="flex space-x-8 items-center mt-3 sm:mt-0">
                {socialIcons.map((item) => {
                  const { id, href, text } = item;
                  return (
                    <Link
                      href={href}
                      key={id}
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="group cursor-pointer">
                      <svg className="icon group-hover:fill-accent fill-black dark:fill-white h-5 w-5 dark:group-hover:fill-accent">
                        <use xlinkHref={`/icons.svg#icon-${text}`}></use>
                      </svg>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="md:flex md:justify-between space-y-2 py-8 flex-row sm:space-y-0">
          <p className="dark:text-jacarta-300 text-jacarta-400 max-w-[360px]">
            Arbipegs, the newest NFT marketplace on the #Arbitrum network for Arbifans, by Arbifans! 
          </p>
          <div className="dark:text-jacarta-400 flex md:flex-wrap flex-col md:flex-row space-x-4 text-sm -ml-4 mt-4 md:mt-0 md:items-end">
            <div className="mt-6 md:hidden"></div>
          <div>
              {/* Empty div is here */}
            </div>
            <div>
              <Link href="https://docs.arbipegs.art/" className="hover:text-accent font-medium dark:hover:text-accent text-jacarta-400 dark:text-jacarta-300">
                  Docs
              </Link>
            </div>
            <div>
              <Link href="/tarms" className="hover:text-accent font-medium dark:hover:text-accent dark:text-jacarta-300 text-jacarta-400">
                Terms of Service
              </Link>
            </div>
            <div>
              <Link href="/privacy-policy" className="hover:text-accent font-medium dark:hover:text-accent text-jacarta-400 dark:text-jacarta-300">
                  Privacy policy
              </Link>
            </div>
            <div>
              <Link href="https://docs.google.com/forms/d/e/1FAIpQLScTEUq134OjTFNfs9KI-iGJzEXtGIPwktPPneq7jdj8HXzUCg/viewform" className="hover:text-accent font-medium dark:hover:text-accent text-jacarta-400 dark:text-jacarta-300">
                  Apply Listing
              </Link>
            </div>
            <div>
              <Link href="https://docs.google.com/forms/d/e/1FAIpQLScTEUq134OjTFNfs9KI-iGJzEXtGIPwktPPneq7jdj8HXzUCg/viewform" className="hover:text-accent font-medium dark:hover:text-accent text-jacarta-400 dark:text-jacarta-300">
                  Apply Launchpad
              </Link>
            </div>
            <div>
              <Link href="/press-kit" className="hover:text-accent font-medium dark:hover:text-accent text-jacarta-400 dark:text-jacarta-300">
                  Press Kit
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </>;
};

export default footer;
