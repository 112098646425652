import React, { useState, useEffect, useRef, MouseEvent, KeyboardEvent } from 'react';
import { trpc } from '../../utils/trpc';
import { logger } from '../../utils/arbLogger';
import router from 'next/router';
import Blockies from 'react-blockies';
import ArbitrumLogoSmallSVG from '../../public/images/svg/ArbitrumLogoSmallSVG';

const Searchbar = ({ setIsOpen }: { setIsOpen: (open: boolean) => void }) => {
  const [searchInput, setSearchInput] = useState('');
  //const [searchResults, setSearchResults] = useState<string[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const searchRef = useRef<HTMLDivElement | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const mockData = ['Result 1', 'Result 2', 'Result 3'];
  const searchResults = trpc.search.search.useQuery({ query: searchInput }, { staleTime: 30, queryKey: ["search.search", { query: searchInput }] });
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  
    if (e.target.value.length > 0) {
      setIsDropdownOpen(true);
      setIsLoading(true); 
    } else {
      setIsDropdownOpen(false);
    }
  };
  
  

  const handleItemClick = (item: any) => {
    setSearchInput(item.text);
    setIsDropdownOpen(false);
    router.push(item.url);
    // Close the menu after navigation
    if(window.innerWidth < 1024) {
      document.dispatchEvent(new CustomEvent('menuToggle'));
    }
  };

  const handleClickOutside = (event: globalThis.MouseEvent) => {
    if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
      setIsLoading(false); 
    }
  };
  

  const handleKeyDown = (event: globalThis.KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleInputFocus = () => {
    setIsLoading(true);
  };
  
  useEffect(() => {
    if ((searchResults.data && searchResults.data.length > 0) || searchResults.error) {
      setIsLoading(false);
    }
  }, [searchResults.data, searchResults.error]);
  
  useEffect(() => {
    if (!isDropdownOpen) {
      setIsLoading(false);
    }
  }, [isDropdownOpen]);
  

  const SearchSkeleton = () => (
    <div className="absolute flex flex-col rounded-2xl p-3 min-w-[300px] w-full bg-jacarta-100 dark:bg-jacarta-600 border dark:border-jacarta-600 border-jacarta-100 transition-all duration-300 ease-in-out mb-5 z-10 mt-2">
      {/* Skeleton for Image */}
      <div className="relative w-1/3 pb-[5%]">
        <div className="absolute top-0 left-0 w-full h-full bg-light-base dark:bg-jacarta-700 animate-pulse dark:animate-pulse"></div>
      </div>
      <div className="relative w-full pb-[13%] mt-2">
        <div className="absolute top-0 left-0 w-full h-full bg-light-base dark:bg-jacarta-700 animate-pulse dark:animate-pulse rounded-xl"></div>
      </div>
    </div>
  );
  
  return (
    <div ref={searchRef} className="relative min-w-[300px] lg:min-w-[50px] xl:max-w-[400px] xml:w-[280px] ml-12 mr-8  basis-3/12  xl:ml-[3%]">
      <input
        type="search"
        placeholder="Search Collections and Users"
        value={searchInput}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        className="text-jacarta-700 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full rounded-2xl border py-[0.6875rem] px-4 pl-10 dark:border-transparent dark:bg-jacarta-600 dark:text-white bg-jacarta-100 dark:placeholder-white"
      />
      <span className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={24}
          height={24}
          className="fill-jacarta-500 h-4 w-4 dark:fill-white"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
        </svg>
      </span>

      {isLoading && <SearchSkeleton />}

      {isDropdownOpen && searchResults.data && searchResults.data.length > 0 && (

        <div className="absolute min-w-[300px] overflow-x-hidden max-h-[390px] border p-2 z-10 w-full mt-1 bg-jacarta-100 border-jacarta-100 rounded-2xl border dark:border-transparent dark:bg-jacarta-600">
          <ul>
          {searchResults.data.map((result, index) => (
            <li
              key={index}
              className="p-4 text-jacarta-700 flex rounded-xl dark:hover:bg-jacarta-800 hover:bg-light-base cursor-pointer dark:text-white"
              onClick={() => handleItemClick(result)}
            >
              <div className="w-[30px] h-[30px] rounded-full mr-[15px]">
                {//@ts-ignore
                result.avatar ? (
                  <img
                    src={//@ts-ignore
                      `${result.avatar}?h=30&w=30`}
                    alt={//@ts-ignore
                      result.text}
                    className="rounded-md h-30 w-30"
                    loading="lazy"
                  />
                ) : (
                  <Blockies
                    seed={//@ts-ignore
                    result.address}
                    size={6.5}
                    scale={4}
                    className="rounded-md"
                  />
                )}
              </div>
              <div className="truncate w-full mt-[2px]">
                {//@ts-ignore
                result.text}
              </div>
              {/* Only add this for collections */}
              <div className='mt-[3px]'><ArbitrumLogoSmallSVG /></div>
              {/* Only add this for collections */}
            </li>
          ))}

          </ul>
        </div>
      )}
    </div>
  );
};

export default Searchbar;
