//@ts-nocheck
import Link from "next/link";
import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notificationModalHide } from "../../redux/modalSlice";
import { useArbUser } from "../../hooks/useArbUser";
import { useAddress, useUser } from "@thirdweb-dev/react";
import { logger } from "../../utils/arbLogger";
import { useExchangeRate } from "../../hooks/useExchangeRate";
import arbConvert from "../../utils/arbConvert";
import ReactDOM from 'react-dom';
import { ethers } from "ethers";

const NotificationModal = () => {
  //@ts-ignore
  const { notificationModal } = useSelector((state) => state.modal);
  const walletAddress = useAddress()
  const { isLoggedIn } = useUser();
  //@ts-ignore
  const me = useArbUser(walletAddress, isLoggedIn)
  logger.trace("ME_NOTIFICAITON", me)
  const dispatch = useDispatch();
  const { data: arbRate } = useExchangeRate("ARB");

  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        dispatch(notificationModalHide());
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        dispatch(notificationModalHide());
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [dispatch]);

  if (!notificationModal) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="modal backdrop-blur-md show block">
      <div className="modal-dialog max-w-[850px] min-w-[65%] md:min-w-[440px]">
        <div className="modal-content bg-white dark:bg-jacarta-900 border dark:border-jacarta-600 border-jacarta-100" ref={modalRef}>
          <div className="modal-header">
            <h5 className="modal-title" id="NotiModalLabel">
              Notifications
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => dispatch(notificationModalHide())}
            >
              <svg
                xmlns="/images"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="fill-jacarta-700 h-6 w-6 dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
              </svg>
            </button>
          </div>

          {/* <!-- Body --> */}
          <div className="modal-body p-6 mx-auto scrollbar scrollbar-thumb-blue-500 scrollbar-thumb-rounded scrollbar-track-blue-100 overflow-y-auto" style={{ maxHeight: 'calc(2.77rem * 9)' }}>
            {me?.allOfMe?.auctionsCreatedNeedingAction?.map((auction, index) => (
              <div key={`auction-created-${auction.auctionId}-${index}`} className="dark:border-jacarta-600 bg-jacarta-100 dark:bg-jacarta-600 dark:hover:bg-jacarta-600 hover:bg-jacarta-100 border-jacarta-100 mb-2 flex flex-grow items-center justify-between py-2.5 rounded-xl px-3">
                <span className="font-display flex text-jacarta-900 font-semibold dark:text-white">
                  <p className="text-xs mt-1 xmd:text-md">
                    Your auction {auction.hasWinner ? "was sold for:" : "ended without any bid"}
                  </p>
                </span>
                <div className="ml- ml-4">
                  <span className="flex items-center whitespace-nowrap">
                    {auction.amount &&
                      <span className="text-accent dark:text-accent font-medium tracking-tight ml-2">
                        {ethers.utils.formatUnits(auction.amount)} ARB <p className="hidden xmd:block"> (${arbConvert(ethers.utils.formatUnits(auction.amount), arbRate?.usd)}) </p>
                      </span>
                    }
                    <Link href={`/item/${auction.collectionAddress}/${auction.tokenId}?auction=${auction.auctionId}`}>
                      <button
                        type="button"
                        className="bg-accent hover:scale-105 hover:bg-accent-dark rounded-xl py-1.5 px-3 ml-3 text-center font-semibold text-xs text-white transition-all md:w-[200px] md:h-[35px] md:text-md"
                        onClick={() => dispatch(notificationModalHide())}
                      >
                        {auction.hasWinner ? "Reclaim Funds" : "Reclaim NFT"}
                      </button>
                    </Link>
                  </span>
                </div>
              </div>
            ))}

            {me?.allOfMe?.auctionsWonNeedingAction?.map((auction, index) => (
              <div key={`auction-won-${auction.auctionId}-${index}`} className="dark:bg-jacarta-700 dark:border-jacarta-600 border border-jacarta-100 bg-light-base dark:hover:bg-jacarta-600 hover:bg-jacarta-100 mb-2 flex flex-grow items-center justify-between py-2.5 rounded-xl px-3 ">
                <span className="font-display flex text-jacarta-900 font-semibold dark:text-white">
                  <p className="text-xs mt-1 xmd:text-md">You won an auction</p>
                </span>
                <div className="ml-4">
                  <span className="flex items-center whitespace-nowrap">
                    <Link href={`/item/${auction.collectionAddress}/${auction.tokenId}?auction=${auction.auctionId}`}>
                      <button
                        type="button"
                        className="bg-accent hover:scale-105 hover:bg-accent-dark rounded-xl py-1.5 px-3 ml-3 text-center font-semibold text-xs text-white transition-all md:w-[200px] md:h-[35px] md:text-md"
                        onClick={() => dispatch(notificationModalHide())}
                      >
                        Claim NFT
                      </button>
                    </Link>
                  </span>
                </div>
              </div>
            ))}

            {me?.allOfMe?.auctionsOutbidded?.map((auction, index) => (
              <div key={`auction-outbidded-${auction.auctionId}-${index}`} className="dark:border-jacarta-600 bg-jacarta-100 dark:bg-jacarta-600 border-jacarta-100 dark:hover:bg-jacarta-600 hover:bg-jacarta-100 mb-2 flex items-center justify-between py-2.5 rounded-xl px-3 ">
                <span className="font-display flex text-jacarta-900 font-semibold dark:text-white">
                  <p className="text-xs mt-1 xmd:text-md">You were outbidded</p>
                </span>
                <div className="ml-4">
                  <span className="flex items-center whitespace-nowrap">
                    <Link href={`/item/${auction.collectionAddress}/${auction.tokenId}?auction=${auction.auctionId}`}>
                      <button
                        type="button"
                        className="bg-accent hover:scale-105 hover:bg-accent-dark rounded-xl py-1.5 px-3 ml-3 text-center font-semibold text-xs text-white transition-all md:w-[200px] md:h-[35px] md:text-md"
                        onClick={() => dispatch(notificationModalHide())}
                      >
                        View Auction
                      </button>
                    </Link>
                  </span>
                </div>
              </div>
            ))}
          </div>

        </div>
      </div>
    </div>,
    document.body
  );
};

export default NotificationModal;
