import React, { useState, useEffect, useRef } from "react";
import Link from 'next/link';
import { useSelector, useDispatch } from "react-redux";
import { transferModalHide } from "../../redux/modalSlice";
import { ThirdwebSDK, useAddress, useSigner } from "@thirdweb-dev/react";
import { useForm } from "react-hook-form";
import SpinningButton from "../arbipegs/button/SpinningTransactionButton";
import { logger } from "../../utils/arbLogger";

const TransferModal = () => {
  //@ts-ignore
  const { transferModal, transferItem } = useSelector((state) => state.modal);
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const signer = useSigner();
  const userAddress = useAddress();
  const [buttonLoading, setButtonLoading] = useState(false);

  const doTransferERC721 = async (recipient: string) => {
    setButtonLoading(true);
    try {
      //@ts-ignore
      const sdk = ThirdwebSDK.fromSigner(signer, process.env.NEXT_PUBLIC_ACTIVE_CHAIN_ID, {
        clientId: process.env.NEXT_PUBLIC_THIRDWEB_API_KEY
      });
      const token = await sdk.getContract(transferItem.contractAddress);
      const tx = await token.erc721.transfer(recipient, BigInt(transferItem.tokenId));
      const receipt = tx.receipt; // the transaction receipt
      logger.debug("TX", tx);
      dispatch(transferModalHide());
      setButtonLoading(false);
    } catch (e) {
      setButtonLoading(false);
      logger.info("Transaction didn't complete:", e);
    }
  };

  const doTransferERC1155 = async (recipient: string, amount: number) => {
    setButtonLoading(true);
    try {
      //@ts-ignore
      const sdk = ThirdwebSDK.fromSigner(signer, process.env.NEXT_PUBLIC_ACTIVE_CHAIN_ID, {
        clientId: process.env.NEXT_PUBLIC_THIRDWEB_API_KEY
      });
      const token = await sdk.getContract(transferItem.contractAddress);
      const tx = await token.erc1155.transfer(recipient,  BigInt(transferItem.tokenId), amount);
      const receipt = tx.receipt; // the transaction receipt
      logger.debug("TX", tx);
      setButtonLoading(false);
      dispatch(transferModalHide());
    } catch (e) {
      setButtonLoading(false);
      logger.info("Transaction didn't complete:", e);
    }
  };
  //@ts-ignore
  const onFormSubmit = data => {
    logger.debug("TRANSFER", data)
    if (transferItem.tokenType == "ERC721") {
      doTransferERC721(data.recipient)
    } else if (transferItem.tokenType == "ERC1155") {
      doTransferERC1155(data.recipient, data.amount)
    }

  };
  //@ts-ignore
  const onFormSubmitErrors = errors => console.error("ERRORS", errors);

  const modalContentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalContentRef.current && !modalContentRef.current.contains(event.target as Node)) {
        dispatch(transferModalHide());
      }
    };

    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        dispatch(transferModalHide());
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKeyPress);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKeyPress);
    };
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit(onFormSubmit, onFormSubmitErrors)}>
        {/* <!-- Transfer NFT Modal --> */}
        <div className={transferModal ? "modal backdrop-blur-md show block" : "modal fade"}>
          <div className="modal-dialog max-w-2xl min-w-[300px] sm:min-w-[400px]">
            <div ref={modalContentRef} className="modal-content dark:bg-jacarta-900 bg-white border dark:border-jacarta-600 border-jacarta-100">
              <div className="modal-title modal-header">
                Transfer NFT
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => dispatch(transferModalHide())}
                >
                  <svg
                    xmlns="/images"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                  </svg>
                </button>
              </div>

              {/* <!-- Body --> */}
              <div>
                <div className="bg-white dark:bg-jacarta-900 p-4 flex items-center rounded-xl mb-5">
                  <div className="flex flex-col mx-auto mb-2 items-center">
                    <span className='mb-4 font-display'>Send To:</span>
                    <input type="text"
                      className=' rounded-xl sm:w-[270px] md:w-[350px] lg:w-[500px] dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 bg-light-base '
                      placeholder='Enter recipient address'
                      {...register('recipient', { required: "Address to transfer to is required" })}
                    />

                  </div>
                </div>
              </div>
              {(transferItem.tokenType == "ERC1155") &&
                <div>
                  <div className="bg-white dark:bg-jacarta-700 p-4 flex items-center rounded-xl mb-5">
                    <div className="flex flex-col mx-auto mb-2 items-center">
                      <span className='mb-4 font-display'>Enter Amount:</span>
                      <input type="text"
                        className=' rounded-xl smw-[270px] md:w-[350px] lg:w-[550px] bg-jacarta-100 dark:bg-jacarta-600 '
                        placeholder='Amount of ERC1155 NFTs you want to send'
                        {...register('amount', { required: "Address to transfer to is required", min: 1, max: transferItem.maxQty })}
                        defaultValue={1}
                      />
                      Max: {transferItem.maxQty}

                    </div>
                  </div>
                </div>
              }
              <div className="bg-white dark:bg-jacarta-900 md:p-4 rounded-xl mb-8">
                <div className="flex flex-col w-100% mb-2 mx-auto w-3/4 items-center max-w-[800px]">
                  <div className=" text-center rounded-xl font-display">
                    Selected NFT:
                  </div>
                  <div>
                    <div className="flex flex-col font-medium lg:min-w-[500px] lg:flex-row border h-full dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 bg-light-base justify-between p-5 h-[100px] mt-3 rounded-xl mb-1 ">
                      <div className="flex flex-col items-center lg:items-start mb-2 lg:mb-0">
                        <img
                          src={transferItem.tokenImage}
                          alt={transferItem.tokenTitle}
                          className="rounded-2lg w-[80px] h-[80px]"
                          loading="lazy"
                        />
                      </div>
                      <div className="flex flex-col items-center">
                        <div className="mb-2 text-md">{transferItem.tokenTitle}</div>
                        <div className="text-sm text-jacarta-400 dark:text-jacarta-300">{transferItem.contractName}</div>
                      </div>
                    </div>
                    {/* Only show this div when the selected NFT is still listed. */}
                    {transferItem.isMarketplaced && transferItem.canCancel &&
                      <div className='mt-5 font-medium'>
                        <div className='border border-[#FFBF00]  p-4 rounded-xl dark:bg-jacarta-700 bg-light-base'>
                          <span className='font-medium text-jacarta-400 dark:text-jacarta-300 opacity-100'>
                            Your NFT is still actively in an listing. You will have to cancel the listing to be able to transfer your NFT
                          </span>
                        </div>
                        {/* <div className="bg-accent hover:bg-accent-dark text-center rounded-xl mt-5">
                        <Link href="#" legacyBehavior>
                          <button className="py-3 px-8 mx-auto font-semibold text-white text-md rounded-xl transition-all">
                            Cancel Listing
                          </button>
                        </Link>
                      </div> */}
                      </div>
                    }
                    {/* Only show this div when the selected NFT is still in an auction. */}
                    {transferItem.isAuctioned && transferItem.canCancel &&
                      <div className='mt-5'>
                        <div className='border border-[#FFBF00] p-4 rounded-xl dark:bg-jacarta-700 bg-light-base'>
                          <span className='font-medium text-jacarta-400 dark:text-jacarta-300 opacity-100'>
                            Your NFT is still actively in an auction. You will have to wait untill the auction is over if there are already bids made on the auction or cancel the auction to be able to transfer your NFT.
                          </span>
                        </div>
                        {/* <div className="bg-accent text-center hover:bg-accent-dark rounded-xl mt-5">
                      <Link href="#" legacyBehavior>
                        <button className="py-3 px-8 mx-auto font-semibold text-white text-md rounded-xl transition-all">
                          Cancel Listing
                        </button>
                      </Link>
                    </div> */}
                      </div>
                    }
                  </div>
                </div>
              </div>
              {/* <!-- end body --> */}
              <div className="modal-footer">
                <div className="flex items-center justify-center space-x-4">
                  <div className="bg-white dark:bg-jacarta-900 p-4 rounded-xl">
                    <div className="flex flex-col mb-2 flex items-center">
                      <span className='mb-4 font-bold items-center'>Are you sure you want to send this NFT to the address you entered?</span>
                      <div className="bg-accent text-center hover:bg-accent-dark rounded-xl w-1/2 ">
                        {!buttonLoading ? (
                          <button className="py-3 px-8 mx-auto font-semibold text-white text-md rounded-xl transition-all">
                            Send NFT
                          </button>
                        ) : (
                          <SpinningButton />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default TransferModal;