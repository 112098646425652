import { Alchemy } from "alchemy-sdk";
import { alchemyArbitrum, alchemyArbitrumGoerli } from "../consts/alchemy";
import { goerliAddresses } from "../consts/contracts";
import { ChainId } from "@thirdweb-dev/sdk";

export function getAlchemy(address: string) {
    if(!process.env.ALCHEMY_WRAPPER) return (new Alchemy(alchemyArbitrum));
    const found = goerliAddresses.find((goerliAddress) => goerliAddress.toLowerCase()==address.toLowerCase())
    
    if (found?.toLowerCase()==address.toLowerCase())     return (new Alchemy(alchemyArbitrumGoerli));
    return (new Alchemy(alchemyArbitrum));
}

export function getThidWebChain(address: string) {
    if(!process.env.ALCHEMY_WRAPPER) return (ChainId.Arbitrum);
    const found = goerliAddresses.find((goerliAddress) => goerliAddress.toLowerCase()==address.toLowerCase())
    if (found?.toLowerCase()==address.toLowerCase())      return (ChainId.ArbitrumGoerli);
    return (ChainId.Arbitrum);
   
}