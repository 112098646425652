import { useState, useEffect } from "react";
import { logger } from "../utils/arbLogger";
import { trpc } from "../utils/trpc";
import { useDispatch } from "react-redux";


export const useExchangeRate = (currency: string) => {
    const dispatch = useDispatch();
    const [data, setData] = useState<any>();
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [fromApis, setFromApis] = useState<boolean>(false);
    const [fromRedis, setFromRedis] = useState<boolean>(false);
    const [fromRedux, setFromRedux] = useState<boolean>(false);

    //Fetch Alchemy Token Metadata
    const { data: arbExchangeRate, isLoading: exchangeLoading, isFetched: exchangeFetched } = trpc.exchange.coinbaseExchangeRate.useQuery({ currency: currency }, {staleTime: 300000, queryKey: ["exchange.coinbaseExchangeRate", {currency: currency}]});
    logger.trace("HOOK COINBASE EXCHANGE RATE INFO", arbExchangeRate, exchangeLoading, exchangeFetched);

    useEffect(() => {
        if (exchangeFetched) {
            setData(arbExchangeRate)
            setLoading(false);
        }

    }, [arbExchangeRate, exchangeFetched]);

    return { data: data, error: error, isLoading: loading, fromApis: fromApis, fromRedux: fromRedux, fromRedis: fromRedis };
}





