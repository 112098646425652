import { Network } from "alchemy-sdk";

export const alchemyArbitrum =
{
    apiKey: process.env.ALCHEMY_ARBITRUM_API_KEY,
    network: Network.ARB_MAINNET,
};

export const alchemyArbitrumGoerli = {
    apiKey: process.env.ALCHEMY_ARBITRUM_GOERLI_API_KEY,
    network: Network.ARB_GOERLI,
};