const socialIcons = [
  {
    id: 1,
    href: "https://www.twitter.com/ArbiPegs",
    text: "twitter",
  },
  {
    id: 2,
    href: "https://discord.gg/hcSkat7Eqh",
    text: "discord",
  },
  {
    id: 3,
    href: "https://arbipegs.medium.com/",
    text: "medium",
  },
  {
    id: 4,
    href: "https://instagram.com/ArbiPegs",
    text: "instagram",
  },
];

export { socialIcons };
