const LOG_LEVEL: LogLevel = 'warn';
/** Signature of a logging function */
export interface LogFn {
    (message?: any, ...optionalParams: any[]): void;
}

/** Basic logger interface */
export interface Logger {
    trace: LogFn;
    debug: LogFn;
    info: LogFn;
    warn: LogFn;
    error: LogFn;
    fatal: LogFn;
}

/** Log levels */
export type LogLevel = 'trace' | 'debug' | 'info' | 'warn' | 'error' | 'fatal';

const NO_OP: LogFn = (message?: any, ...optionalParams: any[]) => { };

/** Logger which outputs to the browser console */
export class ConsoleLogger implements Logger {
    readonly trace: LogFn;
    readonly debug: LogFn;
    readonly info: LogFn;
    readonly warn: LogFn;
    readonly error: LogFn;
    readonly fatal: LogFn;

    constructor(options?: { level?: LogLevel }) {
        const { level } = options || {};

        this.fatal = console.error.bind(console);

        if (level === 'fatal') {
            this.error = NO_OP;
            this.warn = NO_OP;
            this.info = NO_OP;
            this.debug = NO_OP;
            this.trace = NO_OP;

            return;
        }  

        this.error = console.error.bind(console);

        if (level === 'error') {
            this.warn = NO_OP;
            this.info = NO_OP;
            this.debug = NO_OP;
            this.trace = NO_OP;

            return;
        }  

        this.warn = console.warn.bind(console);

        if (level === 'warn') {
            this.info = NO_OP;
            this.debug = NO_OP;
            this.trace = NO_OP;

            return;
        }        

        this.info = console.log.bind(console);

        if (level === 'info') {
            this.debug = NO_OP;
            this.trace = NO_OP;

            return;
        }

        this.debug = console.log.bind(console);

        if (level === 'debug') {
            this.trace = NO_OP;

            return;
        }

        this.trace = console.log.bind(console);
    }
}

export const logger = new ConsoleLogger({ level: LOG_LEVEL});