import { configureStore } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';
import counterReducer from './counterSlice';
import cacheReducer from './cacheSlice';
import modalReducer from './modalSlice';

export const store = configureStore({
	reducer: {
		counter: counterReducer,
		cache: cacheReducer,
		modal: modalReducer,
	},
});
