// export const marketplaceAddress ="0xfE4F7Af5489fd7B5514B2bF9EA575E96047c5Cd6"; //To Be 0x5cFf49aB2925923Cf857d7dB78051e24E8c1Ac87 replaced by NEXT_PUBLIC_MARKETPLACE_CONTRACT_ADDRESS
export const marketplaceAddressGoerli ="0xfE4F7Af5489fd7B5514B2bF9EA575E96047c5Cd6";
export const erc721ArbiTreesAddress ="0x5745a1dccBEdfd05c9208D374f8B4172e3362610";
export const erc1155ArbiBeesAddress ="0xEf47F1f06a84Dcf81Fe021EE42cb41d248942C72";
export const erc1155ArbiBees2AddressToken1 ="0xEf47F1f06a84Dcf81Fe021EE42cb41d248942C72";
export const erc20ARBToken = "0x912CE59144191C1204E64559FE8253a0e49E6548";
export const erc20ARBTokenGoerli = "0x8BccefBe7C4e979E4593fF86B34589C00c73363c";
export const apegtarAdress = "0x1abcaf3dd7e068f4348422856c147d311f8deca5"
export const addressZero = "0x0000000000000000000000000000000000000000";
export const goerliAddresses = [
    marketplaceAddressGoerli,
    erc721ArbiTreesAddress,
    erc1155ArbiBeesAddress,
    apegtarAdress
]