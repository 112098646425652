// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { cancelModalHide } from "../../redux/modalSlice";
import {
  ThirdwebSDK,
  useSigner,
} from "@thirdweb-dev/react";
import { logger } from "../../utils/arbLogger";
import SpinningButton from "../arbipegs/button/SpinningTransactionButton";
import arbConvert from '../../utils/arbConvert';
import { useExchangeRate } from '../../hooks/useExchangeRate';
import ArbitrumSVG from "../../public/images/svg/ArbitrumLogoSVG";

const CancelModal = () => {
  const { cancelModal, cancelItem } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const { data: arbRate } = useExchangeRate("ARB")
  const signer = useSigner();
  const modalRef = useRef(null);

  const doCancel = async (id, type) => {
    if (type === "auction") await cancelAuction(id);
    else cancelDirectListing(id);
  };

  const cancelDirectListing = async (listingId) => {
    setButtonLoading(true);
    try {
      const sdk = ThirdwebSDK.fromSigner(signer, process.env.NEXT_PUBLIC_ACTIVE_CHAIN_ID, {
        clientId: process.env.NEXT_PUBLIC_THIRDWEB_API_KEY
      });
      logger.trace("CANCEL DIRECT LISTING FOR", listingId);
      const contractMarketplace = await sdk.getContract(process.env.NEXT_PUBLIC_MARKETPLACE_CONTRACT_ADDRESS);
      const tx = await contractMarketplace.directListings.cancelListing(
        listingId
      );
      dispatch(cancelModalHide());
      const res = await fetch("/api/marketplace/ping", {
        method: "POST",
        body: JSON.stringify({}),
      }).then((res) => res.json());
      logger.debug("Reindex Marketplace", res);
      setButtonLoading(false);
    } catch (e) {
      setButtonLoading(false);
      logger.info("Cancel Direct Listing Transaction didn't complete:", e);
    }
  };

  const cancelAuction = async (auctionId) => {
    setButtonLoading(true);
    try {
      const sdk = ThirdwebSDK.fromSigner(signer, process.env.NEXT_PUBLIC_ACTIVE_CHAIN_ID, {
        clientId: process.env.NEXT_PUBLIC_THIRDWEB_API_KEY
      });
      logger.trace("CANCEL AUCTION FOR", auctionId);
      const contractMarketplace = await sdk.getContract(process.env.NEXT_PUBLIC_MARKETPLACE_CONTRACT_ADDRESS);
      const tx = await contractMarketplace.englishAuctions.cancelAuction(
        auctionId
      );
      dispatch(cancelModalHide());
      const res = await fetch("/api/marketplace/ping", {
        method: "POST",
        body: JSON.stringify({}),
      }).then((res) => res.json());
      logger.debug("Reindex Marketplace", res);
      setButtonLoading(false);
    } catch (e) {
      setButtonLoading(false);
      logger.info("Cancel auction Transaction didn't complete:", e);
    }
  };

  const [buttonLoading, setButtonLoading] = useState(false);
  const type = cancelItem.listingType === "auction" ? "Auction" : "Listing";
  const isDirectListing =
    cancelItem.listingType === "auction" ? false : true;

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        dispatch(cancelModalHide());
      }
    };

    const handleEscKey = (e) => {
      if (e.key === "Escape") {
        dispatch(cancelModalHide());
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [dispatch]);

  if (
    cancelModal &&
    cancelItem &&
    cancelItem.contractAddress !== "0x0000000000000000000000000000000000000000"
  ) {
    return (
      <div>
        {/* <!-- Cancel Now Modal --> */}
        <div
          className={
            cancelModal ? "modal backdrop-blur-md show block" : "modal fade"
          }
        >
          <div
            className="modal-dialog max-w-2xl min-w-[300px] sm:min-w-[400px]"
            ref={modalRef}
          >
            <div className="modal-content dark:bg-jacarta-900 bg-white border dark:border-jacarta-600 border-jacarta-100">
              <div className="modal-header">
                <h5 className="modal-title" id="buyNowModalLabel">
                  Cancel {type}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => dispatch(cancelModalHide())}
                >
                  <svg
                    xmlns="/images"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                  </svg>
                </button>
              </div>

              {/* <!-- Body --> */}
              {isDirectListing && (
                <div className="modal-body p-6 dark:bg-jacarta-900 bg-white">
                  <div className="mb-2 flex items-center justify-between">
                    <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white">
                      Listed price:
                    </span>
                    <div className="ml-auto">
                      <span className="flex items-center whitespace-nowrap">
                        <span data-tippy-content="ARB">
                          <ArbitrumSVG />
                        </span>
                        <span className="text-accent font-medium tracking-tight ml-2">
                          {cancelItem.tokenPrice} ARB (${arbConvert(cancelItem.tokenPrice, arbRate?.usd)})
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {/* <!-- end body --> */}

              <div className="modal-footer">
                <div className="flex items-center justify-center space-x-4">
                  {!buttonLoading ? (
                    <button
                      type="button"
                      className="bg-accent hover:scale-105 hover:bg-accent-dark rounded-xl py-3 px-8 text-center font-semibold text-white transition-all"
                      onClick={() =>
                        doCancel(cancelItem.listingId, cancelItem.listingType)
                      }
                    >
                      Cancel {type}
                    </button>
                  ) : (
                    <SpinningButton />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default CancelModal;
