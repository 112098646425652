import React, { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import Logo from "./../../public/images/PHONE-NEW-LOGO-ARBIPEGS-JULY-2023-LIGHT.png";
import WhiteLogo from "./../../public/images/PHONE-NEW-LOGO-ARBIPEGS-JULY-2023-DARK.png";
import { useRouter } from 'next/router';
import { useEffect } from "react";
import Searchbar from './Searchbar';


interface SearchbarPhoneProps {
  walletAddress?: string;
}

const SearchbarPhone: React.FC<SearchbarPhoneProps> = ({ walletAddress }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const toggleMenuListener = () => setIsOpen(false);
    
    window.addEventListener('menuToggle', toggleMenuListener);
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 1024) {
        setToggle(false);
      }
    });
  
    return () => {
      window.removeEventListener('menuToggle', toggleMenuListener);
    };
  }, []);;

  
  const router = useRouter();

  const isParentPageActive = (pages: string[], currentPath: string) => {
    // Logic to determine if the parent page is active
  };

  const isChildrenPageActive = (pages: string[], currentPath: string) => {
    // Logic to determine if any child page is active
  };

  return (
    <div className="relative">
      <button
            className="js-mobile-toggle hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full bg-jacarta-100 transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-jacarta-600"
            aria-label="open mobile menu"
            onClick={toggleMenu}
            >
           <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            height={24}
            className="h-4 w-4 dark:fill-white fill-black"
            >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
            </svg>
          </button>

          {isOpen && (
                <div className="lg:hidden js-mobile-menu dark:bg-jacarta-800 fixed inset-0 z-20 ml-auto items-center bg-white lg:visible lg:relative lg:inset-auto lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent">
                    <div className="t-0 dark:bg-jacarta-800 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden">
                    <Link href="/" onClick={() => setIsOpen(false)} className='-ml-4'>
                        <div className="dark:hidden block">
                        <Image
                            src={Logo}
                            height={41}
                            width={190}
                            alt="ArbiPegs | NFT Marketplace"
                        />
                        </div>

                        <div className="hidden dark:block">
                        <Image
                            src={WhiteLogo}
                            height={41}
                            width={190}
                            alt="ArbiPegs | NFT Marketplace"
                        />
                        </div>
                    </Link>

                    <div className="absolute top-7 right-6">
                        <button
                            className="hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full dark:bg-jacarta-600 bg-jacarta-100 transition-colors hover:border-transparent focus:border-transparent dark:border-transparent"
                            aria-label="profile"
                            onClick={() => setIsOpen(false)}
                        >
                            <svg id="Capa_1" enable-background="new 0 0 320.591 320.591" height="12" viewBox="0 0 320.591 320.591" width="12" fill="currentcolor" xmlns="http://www.w3.org/2000/svg"><g><g id="close_1_"><path d="m30.391 318.583c-7.86.457-15.59-2.156-21.56-7.288-11.774-11.844-11.774-30.973 0-42.817l257.812-257.813c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875l-259.331 259.331c-5.893 5.058-13.499 7.666-21.256 7.288z"/><path d="m287.9 318.583c-7.966-.034-15.601-3.196-21.257-8.806l-257.813-257.814c-10.908-12.738-9.425-31.908 3.313-42.817 11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414-6.35 5.522-14.707 8.161-23.078 7.288z"/></g></g></svg>
                        </button> 
                    </div>

                    </div>
                    <div className='mt-[100px] h-full -ml-5 -mr-2'>
                      <Searchbar setIsOpen={setIsOpen}/>
                    </div>
                </div>
            )}

    </div>
  );
};

export default SearchbarPhone;
