import { useState, useEffect } from "react";
import { IArbUser } from "../type/arbipegs";
import { logger } from "../utils/arbLogger";
import { trpc } from "../utils/trpc";
import { useDispatch, useSelector } from "react-redux";
import { BaseContract, ethers, utils } from "ethers";
import { SmartContract, ThirdwebSDK } from "@thirdweb-dev/sdk";
import { cacheMe } from "../redux/cacheSlice";
import { marketplace_api_prefix } from "../consts/marketplace-indexer";




export const useArbUser = (walletAddress: string, me: boolean) => {
    //@ts-ignore
    const { meCache } = useSelector((state) => state.cache);
    const dispatch = useDispatch()
    const abiCoder = new ethers.utils.AbiCoder();


    const [data, setData] = useState<IArbUser | null>(null);
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [user, setUser] = useState<IArbUser | null>(null);
    const [userFetched, setUserFetched] = useState<boolean>(false);

    const [allOfMe, setAllOfMe] = useState<any>({});
    const [meLoading, setMeLoading] = useState<boolean>(false);
    const [meFetched, setMeFetched] = useState<boolean>(false);

    const [allMyAuctionsEnded, setAllMyAuctionsEnded] = useState<any>([]);
    const [allMyAuctionsEndedFetched, setAllMyAuctionsEndedFetched] = useState<boolean>(false);
    const [allMyBiddedAuctions, setAllMyBiddedAuctions] = useState<any>([]);
    const [allMyBiddedAuctionsFetched, setAllMyBiddedAuctionsFetched] = useState<boolean>(false);


    const [fromApis, setFromApis] = useState<boolean>(false);
    const [fromRedis, setFromRedis] = useState<boolean>(false);
    const [fromRedux, setFromRedux] = useState<boolean>(false);
    const { data: balance, isLoading: userBalanceLoading, isFetched: balanceFetched } = trpc.user.userBalance.useQuery({ address: walletAddress }, { staleTime: 300000, queryKey: ["user.userBalance", { address: walletAddress }] });

    //@ts-ignore
    const sdk = new ThirdwebSDK(process.env.NEXT_PUBLIC_ACTIVE_CHAIN_ID, {
        clientId: process.env.NEXT_PUBLIC_THIRDWEB_API_KEY
      });
    const { data: sanityUserProfile, isLoading: sanityLoading, isFetched: sanityFetched } = trpc.user.userProfile.useQuery({ address: walletAddress }, { staleTime: 300000, queryKey: ["user.userProfile", { address: walletAddress }] });
    logger.trace("HOOK SANITY USER PROFILE INFO", walletAddress, sanityUserProfile, sanityLoading, sanityFetched);
    logger.trace("ARBUSER FETCHED", me, userFetched, meFetched, sanityFetched, allMyBiddedAuctionsFetched, allMyAuctionsEndedFetched, balanceFetched)

    useEffect(() => {
        logger.trace("TRACE IN EFFECT API TRIGGER", sanityFetched, loading, fromRedis)
        if (!userFetched && sanityFetched && walletAddress && walletAddress!="") {
            //@ts-ignore
            const arbUser: IArbUser = sanityUserProfile?{
                address: walletAddress,
                username: sanityUserProfile.username,
                avatarUri: "",
                bio: sanityUserProfile.bio,
                coverUri: "",
                socials: [],
            } : {
                address: walletAddress,
                username: walletAddress,
                avatarUri: "",
                bio: "",
                coverUri: "",
                socials: [],
            }
            logger.trace("TRACE IN EFFECT API DO")
            setUser(arbUser);
            //setLoading(false);
            setFromApis(true);
            setUserFetched(true);
            //Refresh Redux Cache
            //dispatch(cacheToken(arbUser));
            //Refresh Redis Cache
            //mutation.mutate({ "key": walletAddress, "value": JSON.stringify(arbUser) });

        }

    }, [walletAddress, sanityFetched, sanityUserProfile, userFetched]);
    
    useEffect(() => {
        const getAllNeededForMe = async (walletAddress: string) => {
            //@ts-ignore
            const contractMarketplace = await sdk.getContract(process.env.NEXT_PUBLIC_MARKETPLACE_CONTRACT_ADDRESS);
            myBidsFromIndexer(walletAddress).then(biddedAuctions => {
                logger.debug("ALL_BIDDED_AUCTIONS_NEW", biddedAuctions)
                setAllMyBiddedAuctions(biddedAuctions.data)
                setAllMyBiddedAuctionsFetched(true)
            })
            myAuctionsEndedFromIndexer(walletAddress).then(auctionsEnded => {
                logger.debug("ALL MY AUCTIONS ENDED NEW", auctionsEnded)
                setAllMyAuctionsEnded(auctionsEnded.data)
                setAllMyAuctionsEndedFetched(true)
            })
            //myOffers(walletAddress, contractMarketplace)
            //myPossibleOffersToAccept(walletAddress)
        }
        const myBidsFromIndexer = async (walletAddress: string) => {
            const response = await fetch(process.env.NEXT_PUBLIC_INDEXER_URL + marketplace_api_prefix + process.env.NEXT_PUBLIC_INDEXER_MARKETPLACE_ID + "/users/" + walletAddress + "/bidded_auctions")
            const bids = await response.json();
            return bids
        }
        const myAuctionsEndedFromIndexer = async (walletAddress: string) => {
            const response = await fetch(process.env.NEXT_PUBLIC_INDEXER_URL + marketplace_api_prefix + process.env.NEXT_PUBLIC_INDEXER_MARKETPLACE_ID + "/users/" + walletAddress + "/auctions_ended")
            const auctions = await response.json();
            return auctions
        }

        const myOffers = async (walletAddress: string, contractMarketplace: SmartContract<BaseContract>) => {
            const myMarketplaceOffers = await contractMarketplace.offers.getAllValid({ offeror: walletAddress });
            logger.trace("ALL MY OFFERS INFO", myMarketplaceOffers);
        }
        const myPossibleOffersToAccept = async (walletAddress: string) => { }

        if (!meFetched && me && !meLoading &&  walletAddress && walletAddress!="") {
            logger.trace("USEARBUSER FETCHING ALL FOR ME")
            setMeLoading(true)
            getAllNeededForMe(walletAddress)
        }
    }, [walletAddress, me, meLoading, meFetched]);

    useEffect(() => {
        logger.trace("CHECK_LENGTH", walletAddress, me, meFetched, balanceFetched, allMyAuctionsEndedFetched, allMyBiddedAuctionsFetched, allMyAuctionsEnded.length, allMyBiddedAuctions.length)
        if (!meFetched && walletAddress && walletAddress!="" && me && balanceFetched && allMyAuctionsEndedFetched && allMyBiddedAuctionsFetched ) {
            logger.trace("USEARBUSER_FULLFILLED_MAIN", me, meFetched)
            const { auctionsCreatedNeedingAction, auctionsWonNeedingAction, auctionsOutbidded } = filterAuctionsEvents(allMyAuctionsEnded, allMyBiddedAuctions, walletAddress)
            const meAll = {
                walletAddress: walletAddress,
                auctionsCreatedNeedingAction: auctionsCreatedNeedingAction,
                auctionsWonNeedingAction: auctionsWonNeedingAction,
                auctionsOutbidded: auctionsOutbidded,
                balance: balance,
                hasNotifications: (auctionsCreatedNeedingAction.length + auctionsWonNeedingAction.length + auctionsOutbidded.length > 0) ? true : false
            }
            setAllOfMe(meAll)
            logger.trace("USEARBUSER_FULLFILLED_MAIN_SET_ME_FETCHED")
            setMeFetched(true)
            dispatch(cacheMe(meAll));
            logger.trace("DISPATCH_ME_CACHE", meAll)
        }

    }, [me, allMyBiddedAuctions, allMyAuctionsEnded, balance, balanceFetched, allMyAuctionsEndedFetched, meFetched, walletAddress, allMyBiddedAuctions, allMyBiddedAuctionsFetched])

    useEffect(() => {
        if (me && meCache  && walletAddress && walletAddress!="" && walletAddress.toLowerCase() == meCache.walletAddress.toLowerCase()) {
            logger.trace("USEARBUSER_FULLFILLED_FROM_CACHE")
            setAllOfMe(meCache)
            logger.trace("USEARBUSER_FULLFILLED_FROM_CACHE_SET_ME_FETCHED")
            setMeFetched(true)
            setFromRedux(true)
        }

    }, [me, meCache, walletAddress])

    useEffect(() => {
        if (userFetched && user && ((me && meFetched) || (!me))) {
            logger.trace("USEARBUSER_FULLFILLED_SET_DATA", me, meFetched)
            setData(user)
        }
    }, [me, meFetched, userFetched, user, walletAddress])


    return { data: data, allOfMe: allOfMe, error: error, meFetched: meFetched, isLoading: loading, fromApis: fromApis, fromRedux: fromRedux, fromRedis: fromRedis };
}
//@ts-ignore
const filterAuctionsEvents = (allMyAuctionsEnded, allMyBiddedAuctions,  walletAddress) => {
    logger.trace("FILTER_AUCTIONS_EVENTS", allMyAuctionsEnded, allMyBiddedAuctions)
    let auctionsCreatedNeedingAction = []
    let auctionsOutbidded = []
    let auctionsWonNeedingAction = []
    //allMyAuctionsEnded.filter((auctionEnded) => !payoutsStatus[auctionEnded.auctionId].creatorStatus).map((auctionNeedingAction) => ({auctionId: auctionNeedingAction.auctionId, hasWinner: winners[auctionNeedingAction.auctionId].winner.toLowerCase()==addressZero?true:false}))
    for (let index = 0; index < allMyAuctionsEnded.length; index++) {
        const current = allMyAuctionsEnded[index];
        const auction = current.auction;
        const auctionId = parseInt(auction.id)
        logger.trace("TRACE_REFACTORING", auction, auctionId)
        //Filter based on creator Status
        //@ts-ignore
        if (auction.reclaimedCreator) break;
        auctionsCreatedNeedingAction.push({ auctionId: auctionId, collectionAddress: auction.assetContractAddress.toLowerCase(), tokenId: auction.tokenId, winner: auction.highestBidder, amount: auction.highestBidAmount, hasWinner: auction.highestBidder? true : false })
    }
    for (let index = 0; index < allMyBiddedAuctions.length; index++) {
        const biddedAuction = allMyBiddedAuctions[index];
        logger.trace("BIDDED_AUCTION", biddedAuction)
        const biddedAuctionId = parseInt(biddedAuction.auction.auctionId)
        //@ts-ignore
        const biddedAuctionEndTime = parseInt(biddedAuction.auction.endTimestamp.toString()) * 1000
        logger.trace("BIDDED_AUCTION_ENDTIME", biddedAuction.auction.endTimestamp.toString(), biddedAuctionEndTime, new Date(biddedAuctionEndTime))
        if (Date.now() < biddedAuctionEndTime) {
            //Auction still live
            logger.trace("BIDDED_AUCTION_WINNING_ADDRESS", biddedAuction.auction.highestBidder, walletAddress)
            if (biddedAuction.auction.highestBidder.toLowerCase() != walletAddress.toLowerCase()) auctionsOutbidded.push({ auctionId: biddedAuctionId, collectionAddress: biddedAuction.auction.assetContract.toLowerCase(), tokenId: biddedAuction.auction.tokenId.toString() })
        } else {
            //Auction ended
            if (biddedAuction.auction.highestBidder.toLowerCase() == walletAddress.toLowerCase()) {
                //Auction winner
                //@ts-ignore
                if (biddedAuction.auction.reclaimedWinner == 0) auctionsWonNeedingAction.push({ auctionId: biddedAuctionId, collectionAddress: biddedAuction.auction.assetContract.toLowerCase(), tokenId: biddedAuction.auction.tokenId.toString() })
            }
        }

    }
    return { auctionsCreatedNeedingAction, auctionsWonNeedingAction, auctionsOutbidded }
}