import React, { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';


interface Props {
	register?: any;
	classes?: string;
	noZero?: boolean;
}


const Price_history: FC<Props> = ({ register, classes, noZero }) => {
	return (
		<div className="relative mb-2 w-full">
			{/* <!-- Price History --> */}
			<div className="tab-pane fade">
				<div className={classes}>
					{/* <!-- Period / Stats --> */}
					<div className=" flex flex-wrap items-center justify-between mb-10">
						<select className="dark:text-white text-black dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 bg-light-base mr-8 min-w-[12rem] rounded-lg py-3.5 text-sm rounded-xl"
							{...register('duration')} >
							{!noZero?<option value="0">Until sold</option>:<></>}
							<option value="1">1 Day</option>
							<option value="3">3 Days</option>
							<option value="7">7 Days</option>
							<option value="14">14 Days</option>
							<option value="30">30 Days</option>
							<option value="60">60 Days</option>
							<option value="90">90 Days</option>
							<option value="180">180 Days</option>
							<option value="365">365 Days</option>
						</select>
					</div>
				</div>

			</div>
		</div>
	);
};

export default Price_history;
