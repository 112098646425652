import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  collections: [],
  me: {},
  users: [],
  tokens: [],
};

export const cacheSlice = createSlice({
  name: "cache",
  initialState,
  reducers: {
    cacheCollection: (state, action) => {
      let found =false;
      for (var i = 0; i < state.collections.length; i++) {
        const collection = state.collections[i];
        if (collection.address.toLowerCase()==action.payload.address.toLowerCase()) {
          state.collections[i]=action.payload;
          found = true;
          break;
        }
      }
      if(!found) state.collections.push(action.payload);
    },
    cacheMe: (state, action) => {
      state.me = action.payload
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  cacheCollection,
  cacheMe,
} = cacheSlice.actions;

export default cacheSlice.reducer;

export const selectCollectionByAddress = (state, address) => state.collections?.filter(collection => collection.address.toLowerCase()==address.toLowerCase());

