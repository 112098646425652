// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import 'tippy.js/dist/tippy.css';
import { trpc } from '../../utils/trpc';
import { selModalHide } from "../../redux/modalSlice";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Auction from "../modal/sale-types/auction";
import FixedPrice from "../modal/sale-types/fixedPrice";

const SelModal = () => {
  const { selModal, selItem } = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  const [tabsActive, setTabsActive] = useState(1);

  useEffect(() => {
    const handleCloseModal = (event) => {
      if (event.key === "Escape") {
        dispatch(selModalHide());
      }
    };

    const handleClickOutsideModal = (event) => {
      if (event.target.classList.contains("modal")) {
        dispatch(selModalHide());
      }
    };

    document.addEventListener("keydown", handleCloseModal);
    document.addEventListener("click", handleClickOutsideModal);

    return () => {
      document.removeEventListener("keydown", handleCloseModal);
      document.removeEventListener("click", handleClickOutsideModal);
    };
  }, [dispatch]);

  const tabsHeadText = [
    {
      id: 1,
      text: "Fixed Price",
      icon: "Fixed",
    },
    {
      id: 2,
      text: "Auction",
      icon: "auction",
    },
  ];

  if (selItem && selItem.contractAddress !== "0x0000000000000000000000000000000000000000") {
    return (
      <div>
        <div className={selModal ? "modal backdrop-blur-md show block" : "modal fade"}>
          <div className="modal-dialog max-w-2xl mb-20 max-h-[90%] min-w-[35%]">
            <div className="modal-content border dark:border-jacarta-600 border-jacarta-100">
              <div className="modal-header dark:bg-jacarta-900 rounded-tr-xl rounded-tl-xl">
                <h5 className="modal-title " id="placeBidLabel">
                  List NFT For Sale
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => dispatch(selModalHide())}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="fill-jacarta-900 h-6 w-6 dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                  </svg>
                </button>
              </div>

              {/* <!-- Body --> */}
              <div className="modal-body p-6 dark:bg-jacarta-900 rounded-br-xl rounded-bl-xl">
                <div className="mb-2 flex items-center flex flex-row ">
                  <img
                    src={selItem.tokenImage}
                    alt={selItem.tokenTitle}
                    className="rounded-2lg h-14 w-14 mb-4 "
                    loading="lazy"
                  />
                  <div className="flex flex-col ml-3">
                    <span className="font-display text-jacarta-900 text-sm font-semibold dark:text-white mb-3 -mt-3">
                      {selItem.tokenTitle}
                    </span>
                    <span className="font-display text-jacarta-900 text-sm font-semibold dark:text-white truncate w-[250px] xml:truncate-none xml:w-full">
                      {selItem.contractName}
                    </span>
                  </div>
                </div>

                <div className="mb-2">
                  <div className="">
                    <Tabs className="min-w-fit tabs">
                      <TabList className="nav nav-tabs flex flex-row justify-around">
                        {/* <!-- Fixed price / Auction tabs --> */}
                        {tabsHeadText.map(({ id, text, icon }) => {
                          return (
                            <Tab className="nav-item" key={id}>
                              <button
                                className={
                                  tabsActive === id
                                    ? "nav-link hover:text-jacarta-900 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white active"
                                    : "nav-link hover:text-jacarta-900 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
                                }
                                onClick={() => setTabsActive(id)}
                              >
                                <span className="font-display text-base font-medium">
                                  {text}
                                </span>
                              </button>
                            </Tab>
                          );
                        })}
                      </TabList>

                      <TabPanel className="tab-content">
                        <FixedPrice />
                      </TabPanel>
                      <TabPanel>
                        <Auction />
                      </TabPanel>
                      <TabPanel>
                        {/* <!-- Details --> */}
                        <div
                          className="tab-pane fade"
                          id="details"
                          role="tabpanel"
                          aria-labelledby="details-tab"
                        ></div>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default SelModal;
